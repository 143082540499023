/*
  Renders a styled link.
  - svg: string, // Url of a svg image
  - isSelected (optional): boolean, // If true the component styling is changed to standout 
  - size: string, // One of 'small', 'medium', 'large',
  - label (optional): string, // Label to show under image
  - href (optional): string, // Href passed to ApplicationLink
  - borderToPaint (optional): string, // One of: 'left', 'right', 'top', and bottom. Paints the given side of the border on hover.
  - onClick (optional): () => void, // Callback passed to ApplicationLink

  (Any other props are passed to the wrapping app link)
*/

import React from 'react';
import cx from 'classnames';
import { EuiText } from '@elastic/eui';

import ApplicationLink from '../../application/application_link';
import NotificationBadge from '../../general/displays/notification_badge';

// Private component that renders a navigational link
// sizes: 'small', 'medium', 'large'
const GlyphLink = ({
  svg: SvgComponent,
  isSelected = false,
  size = 'small',
  borderToPaint = null,
  label = null,
  href = null,
  onClick = null,
  notificationCount = null,
  ...rest
}) => {
  const className = cx(
    'lxnavGlyphLink',
    `lxnavGlyphLink${!!size ? `--${size}` : ''}${!!borderToPaint ? `--border-${borderToPaint}` : ''}`,
    {
      'lxnavGlyphLink--selected': isSelected
    }
  );

  return (
    <ApplicationLink
      href={href}
      onClick={onClick}
      unstyled
      {...rest}
    >
      <div className={className}>
        <div>
          <div className='lxnavGlyphLink__iconWrapper'>
            {!!notificationCount && (
              <NotificationBadge className='lxnavGlyphLink__notification'>
                {notificationCount}
              </NotificationBadge>
            )}

            <SvgComponent className='lxnavGlyphLink__svg' />
          </div>

          {!!label && (
            <EuiText className='lxnavGlyphLink__label'>
              {label}
            </EuiText>
          )}
        </div>
      </div>
    </ApplicationLink>
  );
};

export default GlyphLink;