import React from 'react';

export const withAuthManagement = authManager => Component => props => (
  <Component
    signIn={authManager.signIn}
    signOut={authManager.signOut}
    setUser={authManager.setUser}
    reloadUser={authManager.reloadUser}
    {...props}
  />
);