/**
 * Class representing the user's settings.
 */
class UserSettings {
  constructor() {
    this._canSharePublicly = false;
    this._warningNotificationEmail = null;
    this._warningNotificationsEnabled = false;
    this._anchorRange = -1;
  }

  get canSharePublicly() {
    return this._canSharePublicly;
  }

  set canSharePublicly(val) {
    this._canSharePublicly = val;
  }

  get warningNotificationsEnabled() {
    return this._warningNotificationsEnabled;
  }

  set warningNotificationsEnabled(val) {
    this._warningNotificationsEnabled = val;
  }

  get warningNotificationsEmail() {
    return this._warningNotificationEmail;
  }

  set warningNotificationsEmail(val) {
    this._warningNotificationEmail = val;
  }

  get anchorRange() {
    return this._anchorRange;
  }

  set anchorRange(val) {
    this._anchorRange = val;
  }

  /**
   * Creates an excat copy of the current instance.
   * 
   * @returns {UserSettings}
   */
  clone() {
    const clone = new UserSettings();

    clone._canSharePublicly = this._canSharePublicly;
    clone._warningNotificationEmail = this._warningNotificationEmail;
    clone._warningNotificationsEnabled = this._warningNotificationsEnabled;
    clone._anchorRange = this._anchorRange;

    return clone;
  }

  /**
   * Returns true if the passed paramater is an equal UserSettings istance and false otherwise.
   * 
   * @param {*} other
   * @returns {bool}
   */
  isEqual(other) {
    if (!(other instanceof UserSettings))
      return false;

    if (other === this)
      return true;

      return other._anchorRange === this._anchorRange
      && other._canSharePublicly === this._canSharePublicly
      && other._warningNotificationEmail === this._warningNotificationEmail
      && other._warningNotificationsEnabled === this._warningNotificationsEnabled;
  }

  /**
   * Creates an instance from a settings object as returned by the API.
   * 
   * @param {Object} settingsObj 
   * @returns {UserSettings}
   */
  static createFromApiUserSettings(settingsObj) {
    if (!settingsObj)
      return null;

    const instance = new UserSettings();

    instance._canSharePublicly = settingsObj.is_public_sharing_enabled;
    instance._warningNotificationEmail = settingsObj.warning_notification_email;
    instance._warningNotificationsEnabled = settingsObj.warning_notifications_enabled;
    instance._anchorRange = settingsObj.anchor_range;

    return instance;
  }
}

export default UserSettings;