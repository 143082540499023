import bbox from '@turf/bbox';
import {
  GEOJSON__FEATURE_TYPES,
  GEOJSON__GEOMETRY_TYPES, 
  LONGITUDE_MAX,
  LONGITUDE_MIN,
  LATITUDE_MAX,
  LATITUDE_MIN,
  M2KM
} from './consts';

import { isValidPgnValue } from '../core/pgn/utils';

export const isValidLat = num => isValidPgnValue(num) && num >= LATITUDE_MIN && num <= LATITUDE_MAX;
export const isValidLon = num => isValidPgnValue(num) && num >= LONGITUDE_MIN && num <= LONGITUDE_MAX;

// Retrieves the users current location via the browsers Geolocation API. Returns a Promise with a GeolocationPosition object as it's result.
export const getCurrentUserPosition = () => {
  if (!('geolocation' in navigator))
    throw new Error('Geolocation API is not available');

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

// Creates a GeoJSON feature collection with the given featrues.
export const createGeoJsonFC = (features = []) => ({
  type: 'FeatureCollection',
  features
});

// Converts an array to a GeoJSON FeatureCollection of LineStrings. Makes no assumtions about the shape of the objects.
export const arrayToGeoJsonLSFC = (array, getLat, getLon, getProperties = null) => {
  const comparePos = (pos1, pos2) => pos1.La === pos2.La && pos1.Lo === pos2.Lo;

  const data = [];
  for (let i = 0; i < array.length; i++) {
    const curr = array[i];

    if (!!curr) {
      data.push({
        Lo: getLon(curr),
        La: getLat(curr)
      });
    }
  }

  const features = [];
  let prevPos = data[0];
  for (let i = 1; i < data.length; i++) {

    // When encountering a string of same coordinates take only the last one
    while (i < data.length - 1 && comparePos(prevPos, data[i])) i++;

    const currPos = data[i];

    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [[prevPos.Lo, prevPos.La], [currPos.Lo, currPos.La]]
      },
      properties: !!getProperties ? getProperties(currPos) || {} : {}
    });

    prevPos = currPos;
  }

  return createGeoJsonFC(features);
};

/*
  Determines the geographical bounds of the given array of GeoJSON objects and returns them. Can be directly used with Mapbox's fitBounds method.
  RETURNS: [west: number, south: number, east: number, north: number]
*/
export const getGeoJsonBounds = geoJsonArray => {
  const boundsArr = geoJsonArray.map(geoJson => bbox(geoJson));

  const initialBounds = boundsArr[0];
  const maxBounds = {
    north: initialBounds[3],
    east: initialBounds[2],
    south: initialBounds[1],
    west: initialBounds[0]
  };

  for (let i = 1; i < boundsArr.length; i++) {
    const [west, south, east, north] = boundsArr[i];

    if (maxBounds.north < north)
      maxBounds.north = north;

    if (maxBounds.south > south)
      maxBounds.south = south;

    if (maxBounds.east < east)
      maxBounds.east = east;

    if (maxBounds.west > west)
      maxBounds.west = west;
  }

  const {
    west,
    south,
    east,
    north
  } = maxBounds;

  return [west, south, east, north];
};

export const getBounds = (points, getLongitude, getLatitude) => {
  let north = -Infinity;
  let south = Infinity;
  let east = -Infinity;
  let west = Infinity;

  if (!points)
    points = [];

  for (const p of points) {
    const lng = getLongitude(p);
    const lat = getLatitude(p);

    if (lat > north)
      north = lat;

    if (lat < south)
      south = lat;

    if (lng > east)
      east = lng;

    if (lng < west)
      west = lng;
  }

  return [
    west,
    south,
    east,
    north
  ];
}

export const createCircle = (latitude, longitude, radius, vertexCount = 32, properties = {}) => {
  const distanceX = (radius * M2KM) / (111.320 * Math.cos(latitude * Math.PI / 180));
  const distanceY = (radius * M2KM) / 110.574;

  const pointArray = [];
  for (let i = 0; i < vertexCount; i++) {
    const theta = (i / vertexCount) * (2 * Math.PI);
    const x = distanceX * Math.cos(theta);
    const y = distanceY * Math.sin(theta);

    pointArray.push([longitude + x, latitude + y]);
  }
  pointArray.push(pointArray[0]);

  return {
    type: GEOJSON__FEATURE_TYPES.FEATURE,
    properties: {
      ...properties
    },
    geometry: {
      type: GEOJSON__GEOMETRY_TYPES.POLYGON,
      coordinates: [pointArray]
    }
  };
}