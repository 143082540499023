import React, {
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import {
  navigate,
  usePath
} from 'raviger';
import { throttle } from 'lodash';
import cx from 'classnames';
import {
  EuiHeader,
  EuiBottomBar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiImage,
  EuiTitle,
  EuiButtonEmpty,
  EuiText,
  EuiResizeObserver,
  EuiOutsideClickDetector,
  getBreakpoint
} from '@elastic/eui';
import { useTheme } from '../../hooks/use_theme';
import { vScrollElement } from '../../utils/dom_utils';
import { appEvents } from '../../events/app_events';
import ApplicationLink from './application_link';
import ApplicationLogo from './application_logo';
import GlyphLink from '../general/misc/glyph_link';
import ThemeSwitch from '../general/controls/switches/theme_switch';
import CaretButton from '../general/controls/buttons/caret_button';
import Dropdown from '../general/containers/dropdown';
import { navigateToSignInWithRedirectAfter } from '../../core/authentication/redirect';
import { useGlobalData } from '../../hooks/use_global_data';
import { usePrivacyPreferences } from '../../hooks/use_privacy_preferences';
import { displayPreferencesStorage } from '../../store/store';
import {
  APPLICATION_THEME,
  NAV_PAGES,
  VIEWPORT__SHORT_HEIGHT_LIMIT,
  WARNING__COUNT_LIMIT
} from '../../utils/consts';

//Imported graphics etc
import { ReactComponent as VesselSvg } from '../../assets/icons/vessel.svg';
import { ReactComponent as WorldSvg } from '../../assets/icons/world.svg';
import { ReactComponent as StarSvg } from '../../assets/icons/star.svg';
import { ReactComponent as LogbookSvg } from '../../assets/icons/logbook.svg';
import { ReactComponent as SettingsSvg } from '../../assets/icons/settings.svg';
import { ReactComponent as WarningSvg } from '../../assets/icons/warning_danger.svg';
import adminAccountSvg from '../../assets/images/account_admin.svg';
import userAccountSvg from '../../assets/images/account_user.svg';

// Theme styles
import '../../assets/scss/themes/lxnav_light/lxnav_light.scss';
import '../../assets/scss/themes/lxnav_dark/lxnav_dark.scss';

const isSmallScreen = breakpoint => ['xs', 's'].includes(breakpoint) || window.innerHeight < VIEWPORT__SHORT_HEIGHT_LIMIT;

const defContextValue = {
  outerContainerRef: null,
  scrollTo: null
};

const pathRegex = /^(\/[^/]*).*$/;
const determineSelectedPage = url => {
  const result = pathRegex.exec(url);
  if (result.length !== 2)
    return null;

  for (const key in NAV_PAGES) {
    for (const url of NAV_PAGES[key].baseUrls) {
      if (url === result[1])
        return NAV_PAGES[key];
    }
  }

  return null;
}

/**
 * A React Context to provide access to the root page container and some utility methods.
 * 
 * Public API exposed via props:
 * - outerContainerRef: ReactRef, // React Ref pointing to the DOM element where the page content is rendered
 * - scrollTo: (yPos: number, animLength: number) => Promise<void> // Scrolls the page to the given position in the given time. Returns a Promise that will resolve once the page is scrolled into position. 
 */
export const ApplicationFrameContext = React.createContext(defContextValue);

const ApplicationFrame = ({
  user,
  signOut,
  children,
  stopMonitoringAuth,
  startMonitoringAuth,
  showHeader = true
}) => {
  const [theme] = useTheme();
  const page = usePath();

  const selectedPage = determineSelectedPage(page);

  const [isNavMenuExpanded, setIsNavMenuExpanded] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));
  const [privacyPreferences, setPrivacyPreferences] = usePrivacyPreferences();
  const useSmallLayout = isSmallScreen(breakpoint);

  const warningCount = useGlobalData().getTotalWarningCount();

  const navSectionRef = useRef();     // References the nav section DOM element. Used to hide/display the nav menu on smaller screens.
  const outerContainerRef = useRef(); // Used to measure the page height and make programmatic scrolling work.
  const userMenuRef = useRef();       // Used to make outside click detection properly work with the user menu.
  const previousContentHeightRef = useRef(0);
  const previousScrollTop = useRef(0);
  const previousWindowHeightRef = useRef(0);

  const contextValue = {
    contentContainerRef: outerContainerRef,
    scrollTo: (yPos, animLength) => vScrollElement(outerContainerRef.current, yPos, animLength)
  };

  const onSignOutClicked = async () => {
    try {
      stopMonitoringAuth();
      await signOut();
      navigate('/sign-in');
    } catch (e) {
      appEvents.display_toast.dispatch({
        title: 'Error',
        color: 'warning',
        iconType: 'alert',
        text: (
          <p>
            {e.message || 'Failed to sign-out! Please check your connection.'}
          </p>
        )
      });
    } finally {
      startMonitoringAuth();
    }
  };

  /**
   * On smaller screens it may happen that the content is hidden behind the nav menu, but isn't long enough to trigger overflow.
   * In which case the user has no way to see the hidden content.
   * @returns void
   */
  const applyContentMargin = useCallback(() => {
    const { current: outerContainerDomEl } = outerContainerRef;
    const { current: navSectionDomEl } = navSectionRef;

    if (!outerContainerDomEl || !navSectionDomEl)
      return;

    const navHeight = navSectionDomEl.offsetHeight;

    const diff = useSmallLayout ? outerContainerDomEl.scrollHeight - outerContainerDomEl.clientHeight - navHeight : 0;
    let padding;
    if (diff < 0) {
      const absDiff = diff * -1;
      padding = absDiff < navHeight ? absDiff + 10 : absDiff;
    } else {
      padding = 0;
    }

    outerContainerDomEl.style.paddingBottom = `${padding}px`;
  }, [useSmallLayout]);

  const onContentSizeChanged = useCallback(
    throttle(sizeObj => {
      const { height } = sizeObj;

      if (previousContentHeightRef.current !== height) {
        applyContentMargin();
        previousContentHeightRef.current = height;
      }
    }, 250),
    [applyContentMargin]);

  const onResized = useCallback(
    throttle(() => {
      const newBreakpoint = getBreakpoint(window.innerWidth);

      if (previousWindowHeightRef.current !== window.innerHeight || newBreakpoint !== breakpoint) {
        applyContentMargin();
        previousWindowHeightRef.current = window.innerHeight;
      }

      setBreakpoint(breakpoint);
    }, 25),
    [breakpoint, applyContentMargin]);

  const onContentScrolled = useCallback(
    throttle(() => {
      if (!useSmallLayout)
        return;

      const { current: containerDomEl } = outerContainerRef;
      const { current: navSectionDomEl } = navSectionRef;

      if (!navSectionDomEl || !containerDomEl)
        return;

      const {
        scrollTop: currScrollTop,
        scrollHeight: containerScrollHeight,
        clientHeight: containerClientHeight
      } = containerDomEl;

      const currScrollTopAbs = Math.abs(currScrollTop)

      let shouldShowMenu;
      if (currScrollTop <= 0) {
        shouldShowMenu = true;
      } else if (containerScrollHeight - currScrollTopAbs <= containerClientHeight) {
        shouldShowMenu = false;
      } else {
        const diff = previousScrollTop.current - currScrollTopAbs;
        shouldShowMenu = diff >= 0;
      }

      if (shouldShowMenu)
        navSectionDomEl.style.transform = 'translateY(0)';
      else
        navSectionDomEl.style.transform = 'translateY(100%)';

      previousScrollTop.current = currScrollTopAbs;
    }, 50),
    [useSmallLayout]);

  const onToggleNavMenu = () => {
    setIsNavMenuExpanded(!isNavMenuExpanded);
  };

  useEffect(() => {
    const { current: outerContainerDomEl } = outerContainerRef;

    outerContainerDomEl.addEventListener('scroll', onContentScrolled);
    window.addEventListener('resize', onResized);

    return () => {
      window.removeEventListener('resize', onResized);
      outerContainerDomEl.removeEventListener('resize', onContentScrolled);
    };
  }, [onResized, onContentScrolled]);

  useLayoutEffect(() => {
    window.document.documentElement.className = theme;
  });

  let navSection = null;
  if (!!user) {
    let navigationalItems = [{
      label: 'Home',
      isSelected: selectedPage === NAV_PAGES.HOME,
      href: '/',
      svg: WorldSvg,
      key: 'home'
    }, {
      label: 'Vessels',
      isSelected: selectedPage === NAV_PAGES.VESSELS,
      href: '/vessels',
      svg: VesselSvg,
      key: 'vessels'
    }, {
      label: 'Logbook',
      isSelected: selectedPage === NAV_PAGES.SESSIONS,
      href: '/sessions',
      onClick: () => {
        displayPreferencesStorage.setSelectedLogbookVessel(null);
      },
      svg: LogbookSvg,
      key: 'logbook'
    }, {
      label: 'Favorites',
      isSelected: selectedPage === NAV_PAGES.FAVORITES,
      href: '/favorites',
      svg: StarSvg,
      key: 'favorites'
    }, ...(!useSmallLayout ? [{
      label: 'Settings',
      isSelected: selectedPage === NAV_PAGES.SETTINGS,
      href: '/settings',
      svg: SettingsSvg,
      key: 'settings'
    }] : []), {
      label: 'Warnings',
      isSelected: selectedPage === NAV_PAGES.WARNINGS,
      href: '/warnings',
      svg: WarningSvg,
      key: 'warnings',
      notificationCount: warningCount
    }];

    const navSize = useSmallLayout || !isNavMenuExpanded ? 'medium' : 'large';
    const renderedItems = navigationalItems.map(
      ({
        svg,
        key,
        label,
        isSelected,
        href,
        notificationCount,
        onClick
      }) => (
        <EuiFlexItem
          grow={useSmallLayout}
          key={key}
          className={!useSmallLayout ? `lxnavApplicationFrame__navItem--${navSize}` : null}
        >
          <GlyphLink
            svg={svg}
            label={navSize === 'large' ? label : null}
            borderToPaint={useSmallLayout ? 'bottom' : null}
            isSelected={isSelected}
            href={href}
            size={navSize}
            onClick={onClick}
            notificationCount={!!notificationCount ? notificationCount <= WARNING__COUNT_LIMIT ? notificationCount : `+${WARNING__COUNT_LIMIT}` : null}
          />
        </EuiFlexItem>
      )
    );

    const caretFlexItemStyle = {
      marginTop: 'auto',
      marginBottom: '45px'
    };

    navSection = (
      <EuiFlexGroup
        responsive={false}
        gutterSize='none'
        justifyContent={useSmallLayout ? 'spaceEvenly' : 'flexStart'}
        alignItems={useSmallLayout ? 'stretch' : 'center'}
        direction={useSmallLayout ? 'row' : 'column'}
        className='lxnavApplicationFrame__navSection'
        ref={navSectionRef}
      >
        {renderedItems}

        {(!useSmallLayout &&
          <EuiFlexItem
            style={caretFlexItemStyle}
            grow={false}
          >
            <CaretButton
              showCircle
              isOpen={isNavMenuExpanded}
              closedRotation={-90}
              openRotation={90}
              onClick={onToggleNavMenu}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    );
  }

  let header = null;
  if (showHeader) {
    const leftHeaderItems = [(
      <ApplicationLink
        href='/'
        unstyled
      >
        <ApplicationLogo
          hideLabel={breakpoint === 'xs'}
          light={theme === APPLICATION_THEME.LXNAV_DARK}
        />
      </ApplicationLink>
    )];

    const onOutsideClick = e => {
      const { current } = userMenuRef;
      // Ensure the user didnt click inside the menu either (as it is completly separated from its button in the DOM)
      if (!!current && e.target !== current && !current.contains(e.target))
        setUserMenuOpen(false);
    }

    const rightHeaderItems = [(
      <ThemeSwitch />
    ), (
      <EuiOutsideClickDetector
        onOutsideClick={onOutsideClick}
        isDisabled={!isUserMenuOpen}
      >
        <div
          onClick={() => setUserMenuOpen(!isUserMenuOpen)}
          className='lxnavApplicationFrame__userMenu__button'
        >
          <EuiFlexGroup
            style={{ marginLeft: !useSmallLayout ? '40px' : '20px' }}
            direction='row'
            gutterSize='none'
            alignItems='center'
            responsive={false}
          >
            {!useSmallLayout && !!user && (
              <EuiFlexItem
                grow={false}
                style={{ marginRight: '20px' }}
              >
                <EuiTitle
                  size='xs'
                  style={{ lineHeight: '1rem' }}
                >
                  <h4>
                    {user.username}
                  </h4>
                </EuiTitle>
              </EuiFlexItem>
            )}

            <EuiFlexItem
              style={{
                marginRight: !useSmallLayout ? '16px' : '0',
                width: '28px',
                height: '28px'
              }}
            >
              <EuiImage
                url={(user && user.isAdmin) ? adminAccountSvg : userAccountSvg}
                size='fullWidth'
                alt=''
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CaretButton
                rotationSpeed='fast'
                isOpen={isUserMenuOpen}
                closedRotation={0}
                openRotation={180}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiOutsideClickDetector>
    )];

    const sections = [{
      items: leftHeaderItems
    }, {
      items: rightHeaderItems
    }];

    let userMenuItems;
    if (!!user) {
      userMenuItems = (
        <>
          {user.isAdmin && (
            <EuiFlexItem grow={false}>
              <div
                className='lxnavApplicationFrame__userMenu__item'
                onClick={() => {
                  navigate('/admin');
                  setUserMenuOpen(false);
                }}
              >
                Administration
              </div>
            </EuiFlexItem>
          )}

          <EuiFlexItem grow={false}>
            <div
              className='lxnavApplicationFrame__userMenu__item'
              onClick={() => {
                navigate('/settings');
                setUserMenuOpen(false);
              }}
            >
              Settings
            </div>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div
              className='lxnavApplicationFrame__userMenu__item'
              onClick={() => {
                onSignOutClicked();
                setUserMenuOpen(false);
              }}
            >
              Sign out
            </div>
          </EuiFlexItem>
        </>
      );
    } else {
      userMenuItems = (
        <EuiFlexItem
          grow={false}
          className='lxnavApplicationFrame__userMenu__item'
        >
          <EuiButtonEmpty
            iconType='user'
            flush='left'
            color='text'
            onClick={() => {
              navigateToSignInWithRedirectAfter();
              setUserMenuOpen(false);
            }}
          >
            Sign in
          </EuiButtonEmpty>
        </EuiFlexItem>
      );
    }

    header = (
      <>
        <EuiHeader
          position='fixed'
          sections={sections}
          className='lxnavApplicationFrame__header'
          style={{
            paddingLeft: !useSmallLayout ? '29px' : '15px',
            paddingRight: !useSmallLayout ? '15px' : '5px'
          }}
        />

        {/* The user menu will slide out from underneath the header */}
        <div
          className='lxnavApplicationFrame__userMenu'
          ref={userMenuRef}
        >
          <Dropdown
            isOpen={isUserMenuOpen}
            flushTop
          >
            <EuiFlexGroup
              direction='column'
              gutterSize='none'
              responsive={false}
            >
              {userMenuItems}
            </EuiFlexGroup>
          </Dropdown>
        </div>
      </>
    );
  }

  let bottomBar = null;
  if (!privacyPreferences.cookiesConfirmed) {
    bottomBar = (
      <EuiBottomBar className='lxnavApplicationFrame__bottomBar'>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiText color='ghost'>
              We use cookies to ensure that we give you the best experience on our website. Please see our privacy policy for more information.
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              color='primary'
              fill
              size='s'
              onClick={() => {
                const newPrivacyPreferences = { ...privacyPreferences };
                newPrivacyPreferences.cookiesConfirmed = true;

                setPrivacyPreferences(newPrivacyPreferences);
              }}
            >
              I understand
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
    );
  }

  const className = cx(
    'lxnavApplicationFrame', {
    'lxnavApplicationFrame--small': useSmallLayout,
    'lxnavApplicationFrame--large': !useSmallLayout
  });

  return (
    <div className={className}>
      {header}

      <div className='lxnavApplicationFrame__container'>
        <div
          ref={outerContainerRef}
          className='lxnavApplicationFrame__outerContentContainer'
        >
          <ApplicationFrameContext.Provider value={contextValue}>
            <EuiResizeObserver onResize={onContentSizeChanged}>
              {(resizeRef) => (
                <div
                  ref={resizeRef}
                  className='lxnavApplicationFrame__innerContentContainer'
                >
                  {children}
                </div>
              )}
            </EuiResizeObserver>
          </ApplicationFrameContext.Provider>
        </div>

        {navSection}
      </div>

      {bottomBar}
    </div>
  );
};

export default ApplicationFrame;