/*
  Wrapper around Caret component that is used to render a caret button

  Public API exposed via props:
  - closedRotation: number, // Rotation of the caret when the button is in a closed state
  - openRotation: number, // Rotation of the caret when the button is in a open state 
  - isOpen: boolean, // Is the button in a closed or open state
  - showCircle (optional): boolean, // Draws a circle around the button if true; default is false
  - onClick (optional): void => void, // Callback to invoke when the caret is clicked by the user
  - rotationSpeed (optional): 'xfast'|'fast'|'normal'|'slow'|'xslow', // Animation speed; default is 'normal' 
*/

import React from 'react';
import cx from 'classnames';

import Caret from '../../misc/caret';

const CaretButton = ({
  closedRotation,
  openRotation,
  isOpen,
  showCircle = false,
  onClick = null,
  rotationSpeed = 'normal'
}) => {
  const containerClassName = cx(
    'lxnavCaretButtonContainer',
    {
      'lxnavCaretButtonContainer--showCircle': showCircle,
    }
  );

  return (
    <span
      className={containerClassName}
      onClick={onClick}
    >
      <Caret
        closedRotation={closedRotation}
        openRotation={openRotation}
        isOpen={isOpen}
        rotationSpeed={rotationSpeed}
      />
    </span>
  );
};

export default CaretButton;