/*
Components can subscribe or dispact various events/signals. This file declares all global signals used throughtout the app.
*/

// Imported objects etc
import signals from 'signals';

// Main object exported to the app that holds signal types
export const appEvents = {
    //------------------------------------------------------
    // SIGNAL: user_changed 
    // Reports that user has changed (login/logout action has occured)
    // 
    // PARAMS:
    //  - none
    //-------------------------------------------------------
    user_changed: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: display_toast 
    // Used to request display of toast message.
    // Handling of this signals is in the domain of toast component (components/toast/toast.js)
    // 
    // PARAMS:
    //  - toast - toast object as consumed by the eui toast component
    //  example: {
    //   title: 'Login failed',
    //   color: 'warning',
    //   iconType: 'alert',
    //   text: (
    //     <Fragment>
    //       <p>
    //         Please check the provided information and try again.
    //       </p>
    //     </Fragment>
    //   ),
    //  }
    //-------------------------------------------------------
    display_toast: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: clear_all_toasts 
    // Clears any currently displayed toast message.
    // PARAMS:
    //  - none
    //------------------------------------------------------
    clear_all_toasts: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: display_preferences_changed 
    // Signals that a change occured in user display prefrences.
    // PARAMS:
    //   redirectToSelectedPage: bool - should user be automatically redirected to the selected page when this signal is emitted
    //------------------------------------------------------
    display_preferences_changed: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: user_units_changed 
    // Signals that a change occured in user's units.
    // PARAMS:
    //   NONE
    //------------------------------------------------------
    user_units_changed: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: device_setings_changed 
    // Signals that a change occured in user device settings.
    // PARAMS:
    //   - none
    //------------------------------------------------------
    device_setings_changed: new signals.Signal(),

    //------------------------------------------------------
    // SIGNAL: global_data_loaded 
    // Signals that the global live_data_service has re-loaded data.
    // PARAMS:
    //   - none
    //------------------------------------------------------
    global_data_loaded: new signals.Signal()
};