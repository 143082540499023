import { sprintf } from 'sprintf-js';
import moment from 'moment';

import {
  NODATA,
  UNIT_SLASH,
  UNIT_KM,
  UNIT_NM,
  UNIT_MI,
  UNIT_M,
  UNIT_FT,
  UNIT_IN,
  UNIT_CM,
  UNIT_MM,
  UNIT_M2,
  UNIT_FT2,
  UNIT_MS,
  UNIT_KMH,
  UNIT_KTS,
  UNIT_FPM,
  UNIT_MPH,
  UNIT_KG,
  UNIT_LBS,
  UNIT_CEL,
  UNIT_FAR,
  UNIT_KEL,
  UNIT_MBAR,
  UNIT_MMHG,
  UNIT_INHG,
  UNIT_HPA,
  UNIT_PA,
  UNIT_BAR,
  UNIT_PSI,
  UNIT_KPA,
  UNIT_DEG,
  UNIT_DEGM,
  UNIT_LIT,
  UNIT_GAL,
  UNIT_GAL_IMP,
  UNIT_LPH,
  UNIT_GPH,
  UNIT_GPHI,
  UNIT_LP100KM,
  MM2M,
  CM2M,
  KM2M,
  INCH2M,
  FT2M,
  MI2M,
  NM2M,
  M2MM,
  M2CM,
  M2KM,
  M2INCH,
  M2FT,
  M2NM,
  M2MI,
  KPH2MS,
  KTS2MS,
  MPH2MS,
  FPM2MS,
  MS2KPH,
  MS2KTS,
  MS2MPH,
  MS2FPM,
  MBAR2PA,
  HPA2PA,
  MMHG2PA,
  INHG2PA,
  PA2MBAR,
  PA2HPA,
  PA2MMHG,
  PA2INHG,
  PA2PSI,
  PSI2PA,
  PA2BAR,
  BAR2PA,
  KPA2PA,
  PA2KPA,
  KG2LBS,
  LBS2KG,
  CEL2FAR,
  CEL2KEL,
  IMP_GAL2L,
  L2IMP_GAL,
  GAL2L,
  L2GAL,
  LPH2GPH,
  GPH2LPH,
  LPH2GPH_IMP,
  GPH2LPH_IMP,
  PI,
  RAD2DEG,
  UNIT_BYTE,
  UNIT_KIBIBYTE,
  UNIT_MEBIBYTE,
  UNIT_KILOBYTE,
  UNIT_MEGABYTE,
  KB_SIZE,
  MB_SIZE,
  MIB_SIZE,
  KIB_SIZE,
  BYTES2KIB,
  BYTES2MIB,
  BYTES2MB,
  BYTES2KB
} from './consts'
import {
  displayPreferencesStorage,
  unitsPreferencesStorage
} from '../store/store'
import IanaTimeZone from '../core/constructs/time/iana_time_zone';
import { isValidPgnValue } from '../core/pgn/utils';

export const UNIT_LABEL__UNIT = "Unit"
export const UNIT_LABEL__UNITS = "Units"

export const eUnitsInfoAmount = {
  EU_B: 0,
  EU_Kb: 1,
  EU_Mb: 2,
  EU_Units: 3, // identical to MB*100 but obfuscated
  EU_Kib: 4,
  EU_Mib: 5,
  UNIT_INFO_AMOUNT_MAX: 6
};

export const eUnitsFlow = {
  EU_Lph: 0,
  EU_Gph: 1,
  EU_Gphi: 2,
  UNIT_FLOW_MAX: 3
};

export const eUnitsDistance = {
  EU_Km: 0,
  EU_Nm: 1,
  EU_Mi: 2,
  EU_M: 3,
  EU_Ft: 4,
  EU_In: 5,
  EU_Cm: 6,
  EU_Mm: 7,
  UNIT_DISTANCE_MAX: 8
};

export const eUnitsSpeed = {
  EU_Kph: 0,
  EU_Kts: 1,
  EU_Mph: 2,
  EU_Ms: 3,
  EU_Fpm: 4,
  UNIT_SPEED_MAX: 5
};

export const eUnitsLoad = {
  EU_KgM2: 0,
  EU_LbFt2: 1,
  UNIT_LOAD_MAX: 2
};

export const eUnitsWeight = {
  EU_Kg: 0,
  EU_Lbs: 1,
  UNIT_LOAD_MAX: 2
};

export const eUnitsArea = {
  EU_M2: 0,
  EU_Ft2: 1,
  UNIT_AREA_MAX: 2
};

export const eUnitsVolume = {
  EU_Liter: 0,
  EU_Gallon: 1,
  EU_Galloni: 2,
  UNIT_VOLUME_MAX: 3
};

export const eUnitsPressure = {
  EU_Mbar: 0,
  EU_MmHg: 1,
  EU_InHg: 2,
  EU_Hpa: 3,
  EU_Pa: 4,
  EU_Psi: 5,
  EU_Bar: 6,
  EU_Kpa: 7,
  UNIT_PRESSURE_MAX: 8
};

export const eUnitsTemp = {
  EU_Celsius: 0,
  EU_Fahrenheit: 1,
  EU_Kelvin: 2,
  UNIT_TEMP_MAX: 3
};

export const eUnitsLoLa = {
  EU_DDMMSS: 0,
  EU_DDMMmmm: 1,
  EU_DDdddd: 2,
  EU_DDMMSSss: 3,
  EU_DDMMmmmmm: 4,
  EU_DDdddddd: 5
};

export const eUnitStyle = {
  EUS_None: 0,
  EUS_Short: 1,
  EUS_Long: 2
};

export const eUnitAccuracy = {
  EUA_Approximate: 0,
  EUA_Accurate: 1
};

export const eUnitsDate = {
  EU_MMDDYY: 0,
  EU_DDMMYY: 1
};

export const eUnitsTime = {
  EU_12H: 0,
  EU_24H: 1
};

export const timeFormatStyle = {
  TIME: 0,
  SHORT_TIME: 1,
  LONG_TIME: 2,
  DATE: 3,
  SHORT_DATE: 4,
  LONG_DATE: 5,
  DATE_TIME: 6,
  SHORT_DATE_TIME: 7,
  LONG_DATE_TIME: 8
};

export const eUnitsFuelEco = {
  EU_VolPerDist: 0,
  EU_DistPerVol: 1,
  EU_LP100Km: 2,
  UNIT_FUEL_ECO_MAX: 3
};

class SUnitParams {
  constructor(s, l, k, i, stepS, stepL) {
    this.csShort = s;
    this.csLong = l;

    this.dUnit = k;
    this.dUnitIso = i;
    this.dShortStep = stepS;
    this.dLongStep = stepL;
  }
};

const unitsDistance =
  [
    new SUnitParams(UNIT_KM, "km", M2KM, KM2M, 0.1 * KM2M, 5 * KM2M),
    new SUnitParams(UNIT_NM, "nm", M2NM, NM2M, 0.1 * NM2M, 5 * NM2M),
    new SUnitParams(UNIT_MI, "mi", M2MI, MI2M, 0.1 * MI2M, 5 * MI2M),
    new SUnitParams(UNIT_M, "m", 1, 1, 1, 100),
    new SUnitParams(UNIT_FT, "ft", M2FT, FT2M, 5 * FT2M, 100 * FT2M),
    new SUnitParams(UNIT_IN, "in", M2INCH, INCH2M, 10 * INCH2M, 50 * INCH2M),
    new SUnitParams(UNIT_CM, "cm", M2CM, CM2M, 10 * CM2M, 50 * CM2M),
    new SUnitParams(UNIT_MM, "mm", M2MM, MM2M, 1 * MM2M, 5 * MM2M),
  ];

const unitsInfoAmount =
  [
    new SUnitParams(UNIT_BYTE, "Bytes", 1, 1, 1, 100),
    new SUnitParams(UNIT_KILOBYTE, "Kilobytes", BYTES2KB, KB_SIZE, KB_SIZE, KB_SIZE),
    new SUnitParams(UNIT_MEGABYTE, "Megabytes", BYTES2MB, MB_SIZE, MB_SIZE, MB_SIZE),
    new SUnitParams(` ${UNIT_LABEL__UNITS}`, ` ${UNIT_LABEL__UNITS}`, BYTES2MB, MB_SIZE, MB_SIZE, MB_SIZE),
    new SUnitParams(UNIT_KIBIBYTE, "Kibibytes", BYTES2KIB, KIB_SIZE, KIB_SIZE, KIB_SIZE),
    new SUnitParams(UNIT_MEBIBYTE, "Mebibytes", BYTES2MIB, MIB_SIZE, MIB_SIZE, MIB_SIZE),
  ];

const unitsSpeed =
  [
    new SUnitParams(UNIT_KMH, "km/h", MS2KPH, KPH2MS, 1 * KPH2MS, 5 * KPH2MS),
    new SUnitParams(UNIT_KTS, "knots", MS2KTS, KTS2MS, 1 * KTS2MS, 5 * KTS2MS),
    new SUnitParams(UNIT_MPH, "mph", MS2MPH, MPH2MS, 1 * MPH2MS, 5 * MPH2MS),
    new SUnitParams(UNIT_MS, "m/s", 1, 1, 1, 5),
    new SUnitParams(UNIT_FPM, "fpm", MS2FPM, FPM2MS, 100 * FPM2MS, 10 * FPM2MS),
  ];

const unitsWeight =
  [
    new SUnitParams(UNIT_KG, "kg", 1, 1, 1, 1),
    new SUnitParams(UNIT_LBS, "lbs", KG2LBS, LBS2KG, 1 * LBS2KG, 1 * LBS2KG),
  ];

const unitsVolume =
  [
    new SUnitParams(UNIT_LIT, "l", 1, 1, 1, 10),
    new SUnitParams(UNIT_GAL, "gal", L2GAL, GAL2L, 0.1 * GAL2L, 5 * GAL2L),
    new SUnitParams(UNIT_GAL_IMP, "igal", L2IMP_GAL, IMP_GAL2L, 0.1 * IMP_GAL2L, 5 * IMP_GAL2L),
  ];

const unitsPressure =
  [
    new SUnitParams(UNIT_MBAR, "mbar", PA2MBAR, MBAR2PA, 0.1 * MBAR2PA, 1 * MBAR2PA),
    new SUnitParams(UNIT_MMHG, "mmHg", PA2MMHG, MMHG2PA, 0.1 * MMHG2PA, 1 * MMHG2PA),
    new SUnitParams(UNIT_INHG, "inHg", PA2INHG, INHG2PA, 0.1 * INHG2PA, 1 * INHG2PA),
    new SUnitParams(UNIT_HPA, "hPa", PA2HPA, HPA2PA, 0.1 * HPA2PA, 1 * HPA2PA),
    new SUnitParams(UNIT_PA, "Pa", 1, 1, 0.1, 1),
    new SUnitParams(UNIT_PSI, "psi", PA2PSI, PSI2PA, 0.1 * PSI2PA, 1 * PSI2PA),
    new SUnitParams(UNIT_BAR, "bar", PA2BAR, BAR2PA, 0.1 * BAR2PA, 1 * BAR2PA),
    new SUnitParams(UNIT_KPA, "kPa", PA2KPA, KPA2PA, 0.1 * KPA2PA, 5 * KPA2PA),
  ];

const unitsTemp =
  [
    new SUnitParams(UNIT_CEL, "°C", 1, 1, 1, 5),
    new SUnitParams(UNIT_FAR, "°F", 1, 1, 1, 5),
    new SUnitParams(UNIT_KEL, "°K", 1, 1, 1, 5),
  ];

const unitsArea =
  [
    new SUnitParams(UNIT_M2, "m2", 1, 1, 1, 10),
    new SUnitParams(UNIT_FT2, "ft2", 1, 1, 1, 10),
  ];

const unitsFlow =
  [
    new SUnitParams(UNIT_LPH, "l/h", 1, 1, 1, 10),
    new SUnitParams(UNIT_GPH, "g/h", LPH2GPH, GPH2LPH, 0.2 * GPH2LPH, 5 * GPH2LPH),
    new SUnitParams(UNIT_GPHI, "ig/h", LPH2GPH_IMP, GPH2LPH_IMP, 0.2 * GPH2LPH_IMP, 5 * GPH2LPH_IMP),
  ];

const g_chNORTH = 'N';
const g_chSOUTH = 'S';
const g_chEAST = 'E';
const g_chWEST = 'W';

function Modul(x, y) {
  return x - y * Math.floor(x / y);
}

function unitToStrFormat(Fmt, Val, u, eUnit = eUnitStyle.EUS_Short) {
  let csTmp;

  if (Val === NODATA)
    csTmp = "---";
  else
    csTmp = sprintf(Fmt, Val * u.dUnit);

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp += u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp += u.csLong;

  return csTmp;
}

function unitToStr(u, Val, eUnit = eUnitStyle.EUS_Short) {
  let csTmp;
  let d;

  if (Val === NODATA) {
    csTmp = "---";
  } else {
    d = Val * u.dUnit;

    if (Math.abs(d) < 10)
      csTmp = sprintf("%.1f", d);
    else
      csTmp = sprintf("%.0f", d);
  }

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp += u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp += u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function voltageToStr(Val, eUnit = eUnitStyle.EUS_Short) {
  let csTmp;
  if (Val >= NODATA)
    csTmp = "---";
  else {
    if (Val >= 100)
      csTmp = sprintf("%.0f", Val);
    else if (Val >= 5)
      csTmp = sprintf("%.1f", Val);
    else
      csTmp = sprintf("%.2f", Val);
  }

  if (eUnit !== eUnitStyle.EUS_None)
    csTmp += "V";

  return csTmp;
}

export function currentToStr(Val, eUnit = eUnitStyle.EUS_Short) {
  let csTmp;
  if (Val >= NODATA)
    csTmp = "---";
  else {
    if (Val >= 100)
      csTmp = sprintf("%.0f", Val);
    else if (Val >= 5)
      csTmp = sprintf("%.1f", Val);
    else
      csTmp = sprintf("%.2f", Val);
  }

  if (eUnit !== eUnitStyle.EUS_None)
    csTmp += "A";

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function distToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function distToStr(Val, eSel = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return unitToStr(u, Val, eUnit);
}

export function distSym(eSel = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function speedToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsSpeed(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsSpeed.UNIT_SPEED_MAX) ? unitsSpeed[0] : unitsSpeed[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function speedToStr(Val, eSel = unitsPreferencesStorage.getUnitsSpeed(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsSpeed.UNIT_SPEED_MAX) ? unitsSpeed[0] : unitsSpeed[eSel];
  return unitToStr(u, Val, eUnit);
}

export function speedSym(eSel = unitsPreferencesStorage.getUnitsSpeed(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsSpeed.UNIT_SPEED_MAX) ? unitsSpeed[0] : unitsSpeed[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function pressureToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsPressure(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsPressure.UNIT_PRESSURE_MAX) ? unitsPressure[0] : unitsPressure[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function pressureToStr(Val, eSel = unitsPreferencesStorage.getUnitsPressure(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsPressure.UNIT_PRESSURE_MAX) ? unitsPressure[0] : unitsPressure[eSel];
  return unitToStr(u, Val, eUnit);
}

export function pressureSym(eSel = unitsPreferencesStorage.getUnitsPressure(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsPressure.UNIT_PRESSURE_MAX) ? unitsPressure[0] : unitsPressure[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function weightToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsWight(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsWeight.UNIT_WEIGHT_MAX) ? unitsWeight[0] : unitsWeight[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function weightToStr(Val, eSel = unitsPreferencesStorage.getUnitsWight(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsWeight.UNIT_WEIGHT_MAX) ? unitsWeight[0] : unitsWeight[eSel];
  return unitToStr(u, Val, eUnit);
}

export function weightSym(eSel = unitsPreferencesStorage.getUnitsWight(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsWeight.UNIT_WEIGHT_MAX) ? unitsWeight[0] : unitsWeight[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function areaToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsArea(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsArea.UNIT_AREA_MAX) ? unitsArea[0] : unitsArea[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function areaToStr(Val, eSel = unitsPreferencesStorage.getUnitsArea(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsArea.UNIT_AREA_MAX) ? unitsArea[0] : unitsArea[eSel];
  return unitToStr(u, Val, eUnit);
}

export function areaSym(eSel = unitsPreferencesStorage.getUnitsArea(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsArea.UNIT_AREA_MAX) ? unitsArea[0] : unitsArea[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function volumeToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsVolume(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsVolume.UNIT_VOLUME_MAX) ? unitsVolume[0] : unitsVolume[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function volumeToStr(Val, eSel = unitsPreferencesStorage.getUnitsVolume(), eUnit = eUnitStyle.EUS_Short) {
  return volumeToStrFormat("%.1f", Val, eSel, eUnit);
}

export function volumeSym(eSel = unitsPreferencesStorage.getUnitsVolume(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsVolume.UNIT_VOLUME_MAX) ? unitsVolume[0] : unitsVolume[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function tempToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsTemp(), eUnit = eUnitStyle.EUS_Short) {
  let tmpUnits;
  let u;

  if (eSel >= eUnitsTemp.UNIT_TEMP_MAX) {
    tmpUnits = eUnitsTemp.EU_Celsius;
  }
  else {
    tmpUnits = eSel;
  }

  u = unitsTemp[tmpUnits];

  if (Val !== NODATA) {
    switch (tmpUnits) {
      case eUnitsTemp.EU_Fahrenheit:
        Val = CEL2FAR(Val);
        break;

      case eUnitsTemp.EU_Kelvin:
        Val = CEL2KEL(Val);
        break;

      default:
        break;
    }
  }

  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function tempToStr(Val, eSel = unitsPreferencesStorage.getUnitsTemp(), eUnit = eUnitStyle.EUS_Short) {
  let tmpUnits;
  let u;

  if (eSel >= eUnitsTemp.UNIT_TEMP_MAX) {
    tmpUnits = eUnitsTemp.EU_Celsius;
  }
  else {
    tmpUnits = eSel;
  }

  u = unitsTemp[tmpUnits];

  if (Val !== NODATA) {
    switch (tmpUnits) {
      case eUnitsTemp.EU_Fahrenheit:
        Val = CEL2FAR(Val);
        break;

      case eUnitsTemp.EU_Kelvin:
        Val = CEL2KEL(Val);
        break;

      default:
        break;
    }
  }

  return unitToStrFormat("%.1f", Val, u, eUnit);
}

export function tempSym(eSel = unitsPreferencesStorage.getUnitsTemp(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsTemp.UNIT_TEMP_MAX) ? unitsTemp[0] : unitsTemp[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function flowToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsFlow(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsFlow.UNIT_FLOW_MAX) ? unitsFlow[0] : unitsFlow[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function flowToStr(Val, eSel = unitsPreferencesStorage.getUnitsFlow(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsFlow.UNIT_FLOW_MAX) ? unitsFlow[0] : unitsFlow[eSel];
  return unitToStr(u, Val, eUnit);
}

export function flowSym(eSel = unitsPreferencesStorage.getUnitsFlow(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsFlow.UNIT_FLOW_MAX) ? unitsFlow[0] : unitsFlow[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function depthToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsDepth(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function depthToStr(Val, eSel = unitsPreferencesStorage.getUnitsDepth(), eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];

  let csTmp;
  let d;

  if (Val === NODATA) {
    csTmp = "---";
  } else {
    d = Val * u.dUnit;

    if ((Math.abs(d) < 1) && (d !== 0))
      csTmp = sprintf("%.2f", d);
    else if (Math.abs(d) < 10)
      csTmp = sprintf("%.1f", d);
    else
      csTmp = sprintf("%.0f", d);
  }

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp += u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp += u.csLong;

  return csTmp;
}

export function depthSym(eSel = unitsPreferencesStorage.getUnitsDepth(), eUnit = eUnitStyle.EUS_Short) {
  let csTmp = "";
  let u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];

  if (eUnit === eUnitStyle.EUS_Short)
    csTmp = u.csShort;
  else if (eUnit === eUnitStyle.EUS_Long)
    csTmp = u.csLong;

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function calculateFuelEconomy(fuelUsed, distance) {
  const isFuelUsedValid = isValidPgnValue(fuelUsed) && fuelUsed !== 0;
  const isDistanceValid = isValidPgnValue(distance) && distance !== 0;
  if (!isFuelUsedValid || !isDistanceValid)
    return NODATA;

  return distance / fuelUsed;
}

export function fuelEcoToStrFormat(Fmt, Val, eSel = unitsPreferencesStorage.getUnitsFuelEco(), eSelVol = unitsPreferencesStorage.getUnitsVolume(), eSelDist = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  if (eSel >= eUnitsFuelEco.UNIT_FUEL_ECO_MAX)
    eSel = eUnitsFuelEco.EU_LP100Km;

  let u = new SUnitParams("", "", 1, 1, 1, 1);
  u.csLong = u.csShort = fuelEcoSym(eSel, eSelVol, eSelDist, eUnit).toStdString();

  if (Val !== NODATA) {
    Val = fuelEcoInUnits(Val, eSel, eSelVol, eSelDist);
  }

  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function fuelEcoSym(eSel = unitsPreferencesStorage.getUnitsFuelEco(), eSelVol = unitsPreferencesStorage.getUnitsVolume(), eSelDist = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  let distUnit;
  let volUnit;
  let generatedSym;

  if (eSel === eUnitsFuelEco.EU_LP100Km) {
    generatedSym = UNIT_LP100KM;
  }
  else {
    switch (eSelVol) {
      case eUnitsVolume.EU_Gallon:
        volUnit = UNIT_GAL;
        break;

      case eUnitsVolume.EU_Galloni:
        volUnit = UNIT_GAL_IMP;
        break;

      default:
      case eUnitsVolume.EU_Liter:
        volUnit = UNIT_LIT;
        break;
    }

    switch (eSelDist) {
      case eUnitsDistance.EU_Nm:
        distUnit = UNIT_NM;
        break;

      case eUnitsDistance.EU_Mi:
        distUnit = UNIT_MI;
        break;

      default:
      case eUnitsDistance.EU_Km:
        distUnit = UNIT_KM;
        break;
    }

    if (eSel === eUnitsFuelEco.EU_VolPerDist) {
      generatedSym = volUnit + UNIT_SLASH + distUnit;
    }
    else {
      generatedSym = distUnit + UNIT_SLASH + volUnit;
    }
  }

  return generatedSym;
}

export function fuelEcoToStr(Val, eSel = unitsPreferencesStorage.getUnitsFuelEco(), eSelVol = unitsPreferencesStorage.getUnitsVolume(), eSelDist = unitsPreferencesStorage.getUnitsDistance(), eUnit = eUnitStyle.EUS_Short) {
  if (eSel >= eUnitsFuelEco.UNIT_FUEL_ECO_MAX)
    eSel = eUnitsFuelEco.EU_LP100Km;

  let csTmp;
  let u = new SUnitParams("", "", 1, 1, 1, 1);
  u.csLong = u.csShort = fuelEcoSym(eSel, eSelVol, eSelDist, eUnit);

  if (Val !== NODATA) {
    Val = fuelEcoInUnits(Val, eSel, eSelVol, eSelDist);

    if (Math.abs(Val) < 10)
      csTmp = "%.2f";
    else
      csTmp = "%.0f";
  }

  return unitToStrFormat(csTmp, Val, u, eUnit);
}

//-----------------------------------------------------------------------------------------------------------------------------

export function crsToStr(Val, bUseMagVar, magVar, b360 = false, eUnit = eUnitStyle.EUS_Short) {
  let csTmp;
  let iVal;

  if (magVar === NODATA) {
    bUseMagVar = false;
  }

  if (Val === NODATA) {
    csTmp = "---";
  } else {
    if (bUseMagVar) {
      //fixed because MC=TC-MAGVAR and MAGVAR is negative, if West and positive when East
      Val -= magVar;
    }

    iVal = (Val * RAD2DEG + 360);
    iVal %= 360;
    if (b360 && !iVal)
      iVal = 360;
    csTmp = sprintf("%.3d", iVal);
  }

  if (eUnit !== eUnitStyle.EUS_None) {
    if (bUseMagVar && eUnit === eUnitStyle.EUS_Short)
      csTmp += UNIT_DEGM;
    else
      csTmp += UNIT_DEG;
  }

  return csTmp;
}

export function rollPitchToStr(Val) {
  let csTmp;
  let iVal;

  if (Val === NODATA) {
    csTmp = "---";
  } else {
    iVal = (Val * RAD2DEG);
    csTmp = sprintf("%d", iVal);
  }
  csTmp += UNIT_DEG;

  return csTmp;
}

export function hdgToStr(Val) {
  let csTmp;

  Val = Modul(Val + 4 * PI, 2 * PI);

  if (Val < PI / 8)
    csTmp = "N";
  else if (Val < 3 * PI / 8)
    csTmp = "NE";
  else if (Val < 5 * PI / 8)
    csTmp = "E";
  else if (Val < 7 * PI / 8)
    csTmp = "SE";
  else if (Val < 9 * PI / 8)
    csTmp = "S";
  else if (Val < 11 * PI / 8)
    csTmp = "SW";
  else if (Val < 13 * PI / 8)
    csTmp = "W";
  else if (Val < 15 * PI / 8)
    csTmp = "NW";
  else
    csTmp = "N";

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function lonToStr(lo, eSel = unitsPreferencesStorage.getUnitsLoLa()) {
  let ilo;
  let csTmp;

  csTmp = g_chEAST;
  if (lo === NODATA) {
    switch (eSel) {
      default:
      case eUnitsLoLa.EU_DDMMSS: csTmp += "---°--'--\""; break;
      case eUnitsLoLa.EU_DDMMmmm: csTmp += "---°--.---'"; break;
      case eUnitsLoLa.EU_DDdddd: csTmp += "---.----°"; break;
      case eUnitsLoLa.EU_DDMMSSss: csTmp += "---°--'--.--\""; break;
      case eUnitsLoLa.EU_DDMMmmmmm: csTmp += "---°--.-----'"; break;
      case eUnitsLoLa.EU_DDdddddd: csTmp += "---.------°"; break;
    }
  } else {

    lo = lo * RAD2DEG;
    if (lo < 0) {
      csTmp = g_chWEST;
      lo = -lo;
    }

    switch (eSel) {
      default:
      case eUnitsLoLa.EU_DDMMSS:
        ilo = Math.round(lo * 3600);
        csTmp = csTmp + sprintf("%03d°", ilo / 3600);
        csTmp = csTmp + sprintf("%02d'", (ilo % 3600) / 60);
        csTmp = csTmp + sprintf("%02d\"", ilo % 60);
        break;

      case eUnitsLoLa.EU_DDMMmmm:
        ilo = Math.round(lo * 60000);
        csTmp = csTmp + sprintf("%03d°", ilo / 60000);
        ilo = ilo % 60000;
        csTmp = csTmp + sprintf("%06.3f'", ilo / 1000);
        break;

      case eUnitsLoLa.EU_DDdddd:
        ilo = Math.round(lo * 10000);
        csTmp = csTmp + sprintf("%08.4f°", ilo / 10000);
        break;

      case eUnitsLoLa.EU_DDMMSSss:
        ilo = Math.round(lo * 360000);
        csTmp = csTmp + sprintf("%03d°", ilo / 360000);
        csTmp = csTmp + sprintf("%02d'", (ilo % 360000) / 6000);
        csTmp = csTmp + sprintf("%02.2f\"", (ilo % 6000) / 100.);
        break;

      case eUnitsLoLa.EU_DDMMmmmmm:
        ilo = Math.round(lo * 6000000);
        csTmp = csTmp + sprintf("%03d°", ilo / 6000000);
        ilo = ilo % 6000000;
        csTmp = csTmp + sprintf("%08.5f'", ilo / 100000);
        break;

      case eUnitsLoLa.EU_DDdddddd:
        ilo = Math.round(lo * 1000000);
        csTmp = csTmp + sprintf("%010.6f°", ilo / 1000000);
        break;
    }
  }
  return csTmp;
}

export function latToStr(la, eSel = unitsPreferencesStorage.getUnitsLoLa()) {
  let ila;
  let csTmp;
  csTmp = g_chNORTH;

  if (la === NODATA) {
    switch (eSel) {
      default:
      case eUnitsLoLa.EU_DDMMSS:
        csTmp += "--°--'--\"";
        break;

      case eUnitsLoLa.EU_DDMMmmm:
        csTmp += "--°--.---'";
        break;

      case eUnitsLoLa.EU_DDdddd:
        csTmp += "--.----°";
        break;

      case eUnitsLoLa.EU_DDMMSSss:
        csTmp += "--°--'--.--\"";
        break;

      case eUnitsLoLa.EU_DDMMmmmmm:
        csTmp += "--°--.-----'";
        break;

      case eUnitsLoLa.EU_DDdddddd:
        csTmp += "--.------°";
        break;
    }
    return csTmp;
  }

  la = la * RAD2DEG;
  if (la < 0) {
    csTmp = g_chSOUTH;
    la = -la;
  }

  switch (eSel) {
    default:
    case eUnitsLoLa.EU_DDMMSS:
      ila = Math.round(la * 3600);
      csTmp = csTmp + sprintf("%02d°", ila / 3600);
      csTmp = csTmp + sprintf("%02d'", (ila % 3600) / 60);
      csTmp = csTmp + sprintf("%02d\"", ila % 60);
      break;

    case eUnitsLoLa.EU_DDMMmmm:
      ila = Math.round(la * 60000);
      csTmp = csTmp + sprintf("%02d°", ila / 60000);
      ila = ila % 60000;
      csTmp = csTmp + sprintf("%06.3f'", (ila) / 1000);
      break;

    case eUnitsLoLa.EU_DDdddd:
      ila = Math.round(la * 10000);
      csTmp = csTmp + sprintf("%07.4f°", (ila) / 10000);
      break;

    case eUnitsLoLa.EU_DDMMSSss:
      ila = Math.round(la * 360000);
      csTmp = csTmp + sprintf("%02d°", ila / 360000);
      csTmp = csTmp + sprintf("%02d'", (ila % 360000) / 6000);
      csTmp = csTmp + sprintf("%02.2f\"", (ila % 6000) / 100.);
      break;

    case eUnitsLoLa.EU_DDMMmmmmm:
      ila = Math.round(la * 6000000);
      csTmp = csTmp + sprintf("%02d°", ila / 6000000);
      ila = ila % 6000000;
      csTmp = csTmp + sprintf("%08.5f'", (ila) / 100000);
      break;

    case eUnitsLoLa.EU_DDdddddd:
      ila = Math.round(la * 1000000);
      csTmp = csTmp + sprintf("%09.6f°", (ila) / 1000000);
      break;
  }

  return csTmp;
}

//-----------------------------------------------------------------------------------------------------------------------------

export function rpmToStr(Val, eUnit = eUnitStyle.EUS_Short) {
  return Val !== NODATA ? Math.floor(Val / 10) * 10 : '---';
}

//-----------------------------------------------------------------------------------------------------------------------------

export function infoAmountToStrFormat(Fmt, Val, eSel = eUnitsInfoAmount.EU_B, eUnit = eUnitStyle.EUS_Short) {
  let u = (eSel >= eUnitsInfoAmount.UNIT_DISTANCE_MAX) ? unitsInfoAmount[0] : unitsInfoAmount[eSel];
  return unitToStrFormat(Fmt, Val, u, eUnit);
}

export function infoAmountToStr(Val, eSel = eUnitsInfoAmount.EU_B, eUnit = eUnitStyle.EUS_Short, eAcc = eUnitAccuracy.EUA_Approximate) {
  if (eSel >= eUnitsInfoAmount.UNIT_INFO_AMOUNT_MAX)
    eSel = eUnitsInfoAmount.EU_B;

  let fmt = null;
  if (eAcc === eUnitAccuracy.EUA_Approximate) {
    if (eSel === eUnitsInfoAmount.EU_Units) {
      Val = Val * 100;
      fmt = "%.0f";
    } else if (eSel === eUnitsInfoAmount.EU_B) {
      fmt = "%.0f";
    } else {
      fmt = "%.2f";
    }
  } else if (eAcc === eUnitAccuracy.EUA_Accurate) {
    if (eSel === eUnitsInfoAmount.EU_Units) {
      Val = Val * 100;
      fmt = "%.3f";
    } else if (eSel === eUnitsInfoAmount.EU_B) {
      fmt = "%.0f";
    } else {
      fmt = "%.3f";
    }
  }

  const u = unitsInfoAmount[eSel];
  return unitToStrFormat(fmt, Val, u, eUnit);
}

//-----------------------------------------------------------------------------------------------------------------------------

export function getTimeFormatStr(
  style,
  timeFormat = displayPreferencesStorage.getTimeFormat(),
  dateFormat = displayPreferencesStorage.getDateFormat()
) {
  // Determine time format string
  let normalTime = [timeFormatStyle.TIME, timeFormatStyle.DATE_TIME].includes(style);
  let shortTime = [timeFormatStyle.SHORT_TIME, timeFormatStyle.SHORT_DATE_TIME].includes(style);
  let longTime = [timeFormatStyle.LONG_TIME, timeFormatStyle.LONG_DATE_TIME].includes(style);

  let timeString = '';
  if (normalTime || shortTime || longTime) {
    switch (timeFormat) {
      case eUnitsTime.EU_12H: {
        if (shortTime)
          timeString = 'H:mm A';
        else if (longTime)
          timeString = 'HH:mm:ss A';
        else
          timeString = 'H:mm:ss A'
        break;
      }

      case eUnitsTime.EU_24H:
      default: {
        if (shortTime)
          timeString = 'H:mm';
        else if (longTime)
          timeString = 'HH:mm:ss';
        else
          timeString = 'H:mm:ss'
        break;
      }
    }
  }

  // Determine date format string
  let normalDate = [timeFormatStyle.DATE, timeFormatStyle.DATE_TIME].includes(style);
  let shortDate = [timeFormatStyle.SHORT_DATE, timeFormatStyle.SHORT_DATE_TIME].includes(style);
  let longDate = [timeFormatStyle.LONG_DATE, timeFormatStyle.LONG_DATE_TIME].includes(style);

  let dateString = '';
  if (normalDate || shortDate || longDate) {
    switch (dateFormat) {
      case eUnitsDate.EU_MMDDYY: {
        if (shortDate)
          dateString = 'MM/DD/YY';
        else if (longDate)
          dateString = 'MMMM DD YYYY';
        else
          dateString = 'MM/DD/YYYY';
        break;
      }

      case eUnitsDate.EU_DDMMYY:
      default: {
        if (shortDate)
          dateString = 'DD.MM.YY';
        else if (longDate)
          dateString = 'DD MMMM YYYY';
        else
          dateString = 'DD.MM.YYYY';
        break;
      }
    }
  }

  if (!!timeString && !!dateString)
    return `${timeString} ${dateString}`;
  else if (!!timeString)
    return timeString;
  else
    return dateString;
}

export function timeToStr(
  date,
  style = timeFormatStyle.SHORT_DATE_TIME,
  tzName = displayPreferencesStorage.getTimeZone(),
  timeFormat = displayPreferencesStorage.getTimeFormat(),
  dateFormat = displayPreferencesStorage.getDateFormat()
) {
  return timeToFormat(date, getTimeFormatStr(style, timeFormat, dateFormat), tzName);
}

export function timeToFormat(date, format, tzName = displayPreferencesStorage.getTimeZone()) {
  const offset = IanaTimeZone.fromName(tzName).getOffsetInMinutes(date);
  const dt = moment(date).utcOffset(offset);

  return dt.format(format);
}

export function durationToStr(duration, style = eUnitStyle.EUS_Short) {
  if (duration === null || duration === undefined || duration === NODATA)
    return style === eUnitStyle.EUS_Long ? '--- hour(s) --- minute(s)' : '---h ---m';

  if (duration < 0)
    duration = 0;

  if (duration < 60 * 60 * 24) {
    const minuteCount = duration / 60;

    const hours = minuteCount / 60;
    const minutes = minuteCount % 60;
    if (style === eUnitStyle.EUS_Long)
      return sprintf('%d hour(s) %d minute(s)', hours, minutes);
    else
      return sprintf('%dh %dm', hours, minutes);
  } else if (duration < 60 * 60 * 24 * 365) {
    const hourCount = duration / 3600;

    const days = hourCount / 24;
    const hours = hourCount % 24;
    if (style === eUnitStyle.EUS_Long)
      return sprintf('%d day(s) %d hour(s)', days, hours);
    else
      return sprintf('%dd %dh', days, hours);
  } else {
    const dayCount = duration / (3600 * 24);

    const years = dayCount / 365;
    const days = dayCount % 365;
    if (style === eUnitStyle.EUS_Long)
      return sprintf('%d year(s) %d day(s)', years, days);
    else
      return sprintf('%dy %dd', years, days);
  }
}

//-----------------------------------------------------------------------------------------------------------------------------
export function distInUnits(Val, eSel = unitsPreferencesStorage.getUnitsDistance()) {
  var u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return u.dUnit * Val;
}

export function speedInUnits(Val, eSel = unitsPreferencesStorage.getUnitsSpeed()) {
  var u = (eSel >= eUnitsSpeed.UNIT_SPEED_MAX) ? unitsSpeed[0] : unitsSpeed[eSel];
  return u.dUnit * Val;
}

export function pressInUnits(Val, eSel = unitsPreferencesStorage.getUnitsPressure()) {
  var u = (eSel >= eUnitsPressure.UNIT_PRESSURE_MAX) ? unitsPressure[0] : unitsPressure[eSel];
  return u.dUnit * Val;
}

export function weightInUnits(Val, eSel = unitsPreferencesStorage.getUnitsWight()) {
  var u = (eSel >= eUnitsWeight.UNIT_WEIGHT_MAX) ? unitsWeight[0] : unitsWeight[eSel];
  return u.dUnit * Val;
}

export function areaInUnits(Val, eSel = unitsPreferencesStorage.getUnitsArea()) {
  var u = (eSel >= eUnitsArea.UNIT_AREA_MAX) ? unitsArea[0] : unitsArea[eSel];
  return u.dUnit * Val;
}

export function volumeInUnits(Val, eSel = unitsPreferencesStorage.getUnitsVolume()) {
  var u = (eSel >= eUnitsVolume.UNIT_VOLUME_MAX) ? unitsVolume[0] : unitsVolume[eSel];
  return u.dUnit * Val;
}

export function tempInUnits(Val, eSel = unitsPreferencesStorage.getUnitsTemp()) {
  var u = (eSel >= eUnitsTemp.UNIT_TEMP_MAX) ? unitsTemp[0] : unitsTemp[eSel];
  return u.dUnit * Val;
}

export function flowInUnits(Val, eSel = unitsPreferencesStorage.getUnitsFlow()) {
  var u = (eSel >= eUnitsFlow.UNIT_FLOW_MAX) ? unitsFlow[0] : unitsFlow[eSel];
  return u.dUnit * Val;
}

export function depthInUnits(Val, eSel = unitsPreferencesStorage.getUnitsDepth()) {
  var u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return u.dUnit * Val;
}

export function fuelEcoInUnits(Val, eSel = unitsPreferencesStorage.getUnitsFuelEco(), eSelVol = unitsPreferencesStorage.getUnitsVolume(), eSelDist = unitsPreferencesStorage.getUnitsDistance()) {
  let distanceFactor = 1;
  let volumeFactor = 1;

  switch (eSelVol) {
    case eUnitsVolume.EU_Gallon:
      volumeFactor = GAL2L;
      break;

    case eUnitsVolume.EU_Galloni:
      volumeFactor = IMP_GAL2L;
      break;

    default:
    case eUnitsVolume.EU_Liter:
      volumeFactor = 1;
      break;
  }

  switch (eSelDist) {
    case eUnitsDistance.EU_Nm:
      distanceFactor = M2NM;
      break;

    case eUnitsDistance.EU_Mi:
      distanceFactor = M2MI;
      break;

    default:
    case eUnitsDistance.EU_Km:
      distanceFactor = M2KM;
      break;
  }

  if (eSel === eUnitsFuelEco.EU_LP100Km) {
    Val = 100. / (Val * M2KM);
  }
  else if (eSel === eUnitsFuelEco.EU_VolPerDist) {
    Val = 1. / (Val * distanceFactor * volumeFactor);
  }
  else {
    Val = (Val * distanceFactor * volumeFactor);
  }

  return Val;
}

//-----------------------------------------------------------------------------------------------------------------------------
export function distInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsDistance()) {
  var u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return u.dUnitIso * Val;
}

export function speedInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsSpeed()) {
  var u = (eSel >= eUnitsSpeed.UNIT_SPEED_MAX) ? unitsSpeed[0] : unitsSpeed[eSel];
  return u.dUnitIso * Val;
}

export function pressInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsPressure()) {
  var u = (eSel >= eUnitsPressure.UNIT_PRESSURE_MAX) ? unitsPressure[0] : unitsPressure[eSel];
  return u.dUnitIso * Val;
}

export function weightInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsWight()) {
  var u = (eSel >= eUnitsWeight.UNIT_WEIGHT_MAX) ? unitsWeight[0] : unitsWeight[eSel];
  return u.dUnitIso * Val;
}

export function areaInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsArea()) {
  var u = (eSel >= eUnitsArea.UNIT_AREA_MAX) ? unitsArea[0] : unitsArea[eSel];
  return u.dUnitIso * Val;
}

export function volumeInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsVolume()) {
  var u = (eSel >= eUnitsVolume.UNIT_VOLUME_MAX) ? unitsVolume[0] : unitsVolume[eSel];
  return u.dUnitIso * Val;
}

export function tempInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsTemp()) {
  var u = (eSel >= eUnitsTemp.UNIT_TEMP_MAX) ? unitsTemp[0] : unitsTemp[eSel];
  return u.dUnitIso * Val;
}

export function flowInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsFlow()) {
  var u = (eSel >= eUnitsFlow.UNIT_FLOW_MAX) ? unitsFlow[0] : unitsFlow[eSel];
  return u.dUnitIso * Val;
}

export function depthInIsoUnits(Val, eSel = unitsPreferencesStorage.getUnitsDepth()) {
  var u = (eSel >= eUnitsDistance.UNIT_DISTANCE_MAX) ? unitsDistance[0] : unitsDistance[eSel];
  return u.dUnitIso * Val;
}