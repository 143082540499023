/*
  Collection of functions for manipulating/reading vessels.
*/

import { unixToDate } from '../utils/general';
import { makeQueryString } from '../utils/web';
import { apiClient as client } from './base/api_client';

/**
 * VESSEL_OBJ = {
 *   id: number,                     // Identifier
 *   name: string,                   // End-user supplied label
 *   imei: string,                   // Device IMEI (secondary identifier)
 *   is_current_user_owner: boolean, // Is currently signed in user the owner of the vessel
 *   is_electric: boolean,           // Is the vessel marked as electric
 *   is_favorite: boolean,           // Is the vessel marked as a favorite
 *   is_publicly_shared: boolean,    // Is the vessel publicly shared
 *   user_id: number,                // Id of the owner (NOTE: this field will only be present if the logged-in user is an admin)
 *   created_at: Date                // Date of when the vessel was created
 * }
 *
 * ACTIVE_SESSION_OBJ = {
 *   SessionData: {
 *     [pgnNumber]: {
 *       DataSources: {
 *         Data: PGN_OBJ[],
 *         Name: string
 *       }
 *     }
 *   },
 *   Pos: { // vessel's position
 *     T: number,   // timestamp of position
 *     Lat: number, // latitude
 *     Lon: number, // longitude
 *     Cog: number  // course over ground
 *   },
 *   Online: bool,           // is the vessel online
 *   TripCnt: number,        // number of trips of vessel
 *   TripDist: number,       // distance travelled by vessel (in kilometers)
 *   TotFuel: number,        // total amount of fuel used by vessel (in liters)
 *   TOnl: number,           // amount of time vessel has been online (in seconds)
 *   Warnings: WARNING_OBJ[] // warnings raised by vessels
 * }
 */

const mapVessel = v => {
  const {
    created_at,
    ...rest
  } = v;

  return {
    ...rest,
    created_at: unixToDate(created_at)
  };
}

/*
  Creates a new vessel. Can be used to create vessels for other users (by passing that user's id), but the logged-in user must be an admin.
  Return a Promise containing the newly created vessel (VESSEL_OBJ).

  example: createVessel('12345', 'My vessel').then(({ id, name, imei }) => { ... }).catch(e => { ... })
*/
export async function createVessel(imei, name, isElectric = false, saveSessions = true, userId = null) {
  const data = {
    imei,
    name,
    is_electric: isElectric,
    save_sessions: saveSessions
  };

  // Only include the key if not null
  if (userId != null)
    data['userId'] = userId;

  const v = await client.post(
    'api/vessels',
    data
  );

  return mapVessel(v);
}

/*
  Updates the given vessel. Only values passed are updated.
  Returns a Promise with the modified vessel (VESSEL_OBJ) as its result.

  SUPPORTED FIELDS:
    -imei: string (of digits only), // eg. '2345345378433'
    -name: string, //eg. 'My boat'
    -is_electric: boolean //eg.: true
    -is_favorite: boolean //eg.: true
    -is_publicly_shared: boolean // is the vessel (and its live data) available to the public
*/
export async function patchVessel(values, id) {
  const v = await client.patch(
    `api/vessels/${id}`, {
      ...values
    }
  );

  return mapVessel(v);
}

/*
  Changes whether the vessel is publicly shared.
  Returns a Promise with the modified vessel (VESSEL_OBJ) as its result.
*/
export async function setIsVesselPublic(isPublic, vesselId) {
  const values = { is_publicly_shared: isPublic };

  const v = await patchVessel(values, vesselId);

  return mapVessel(v);
}

/*
  Changes whether the vessel is favorized.
  Returns a Promise with the modified vessel (VESSEL_OBJ) as its result.
*/
export async function setIsVesselFavorite(isFavorite, vesselId) {
  const values = { is_favorite: isFavorite };

  const v = await patchVessel(values, vesselId);

  return mapVessel(v);
}

/*
  Gets the display configuration used for this vessel by this app (used on the session details page).
  Returns a Promise containing the configuration originally set by 'saveConfig'.
*/
export function getConfig(vesselId) {
  return client.get(`api/vessels/${vesselId}/configuration`);
}

/*
  Saves the user defined configuration for the given vessel. Can actually contain anything, since the server only stores this value.
  Returns a Promise containing no data.
*/
export function saveConfig(vesselId, configuration) {
  return client.post(
    `api/vessels/${vesselId}/configuration`, {
      ...configuration
    }
  );
}

/*
  Gets array of the user's vessels. Can load the vessels of other user (by passing the user's id), but the logged-in user must be an admin.
  Returns a Promise with an array of the user's vessels (VESSEL_OBJ[]) as the result.
*/
export async function getUserVessels(userId = null) {
  const userPath = !!userId ? userId : 'me';

  const vessels = await client.get(`api/users/${userPath}/vessels`);

  return vessels.map(mapVessel);
}

/*
  Gets an array of all the vessels.
  Returns a Promise containing an array of vessels (VESSEL_OBJ[]).

  NOTE: Only admins may invoke this endpoint!
*/
export async function getVessels() {
  const vessels = await client.get('api/vessels');

  return vessels.map(mapVessel);
}

/*
  Gets the given vessel.
  Returns a Promise containing the requested vessel (VESSEL_OBJ).
*/
export async function getVessel(vesselId) {
  const v = await client.get(`api/vessels/${vesselId}`);

  return mapVessel(v);
}

/*
  Deletes the given vessel from the server.
  Returns a Promise with no result.
*/
export function deleteVessel(id) {
  return client.delete(`api/vessels/${id}`);
}

/*
  Sends a message to the given vessel. The message can be displayed by some devices (eg. e500).
  Returns a Promise with no result.
*/
export function sendMessage(message, vesselId) {
  return client.put(
    `api/vessels/${vesselId}/message`, {
      msg: message
    }
  );
}

/*
  Resets a vessel's statistics.
  Returns a Promise with no result.
*/
export function resetStatistics(vesselId) {
  return client.put(
    `api/vessels/${vesselId}/resetstats`
  );
}

/*
  Resets the on-board tracking device.
*/
export function resetTrackingDevice(vesselId) {
  return client.put(`api/vessels/${vesselId}/reset`, {});
}

/*
  Returns the vessels live data.
*/
export function getLiveData(vesselId) {
  const query = makeQueryString({ vesselID: vesselId });

  return client.get(`api/livedata${query}`);
}

export function getLiveDataBatched(vesselIds) {
  const query = makeQueryString({ vesselIDs: vesselIds.join(',') });

  return client.get(`api/livedata${query}`);
}

export function getGeofences(vesselId) {
  return client.get(`api/vessels/${vesselId}/geofences`);
}

export function updateGeofences(vesselId, geofences) {
  return client.put(`api/vessels/${vesselId}/geofences`, geofences);
}

export function deleteGeofences(vesselId) {
  return client.delete(`api/vessels/${vesselId}/geofences`);
}

export function getAnchorZone(vesselId) {
  return client.get(`api/vessels/${vesselId}/anchor`);
}

export function updateAnchorZone(vesselId, radius, isEnabled) {
  const data = {
    Enabled: isEnabled,
    Radius: radius
  };

  return client.put(`api/vessels/${vesselId}/anchor`, data);
}