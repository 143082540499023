/*
  Used as a site wide loading spinner. Can optionally display a message below the loader.
  
  Public API exposed via props:
  - size (optional): string, // Passed to EuiLoadingSpinner as size prop; defaults to l
  - loaderMsg (optional): ReactNode // Anything to render below the loader 
*/

import React from 'react';
import { EuiLoadingSpinner } from '@elastic/eui';

const LoadingSpinner = ({
  size = 'l',
  message = null
}) => (
  <div className='lxnavLoadingSpinner'>
    <div className='lxnavLoadingSpinner__container'>
      <div className='lxnavLoadingSpinner__spinner'>
        <EuiLoadingSpinner size={size} />
      </div>

      {!!message && (
        <span>
          {message}
        </span>
      )}
    </div>
  </div>

);

export default LoadingSpinner;