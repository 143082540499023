import { apiClient } from '../../api/base/api_client';
import AuthInvalidatedListener from './auth_invalidated_listener';
import AuthManager from './auth_manager';

/**
 * Global authentication singletons used throughout the app.
 */
let authManager;
let authInvalidatedListener;

/**
 * Retrieves and, if required, instantiates the global manager instance.
 * 
 * @returns {AuthManager}
 */
export function getAuthManager() {
  if (!authManager)
    authManager = new AuthManager();

  return authManager;
};

/**
 * Retrieves and, if required, instantiates the global listener instance.
 * 
 * @returns {AuthManager}
 */
export function getAuthInvalidatedListener() {
  if (!authInvalidatedListener) {
    const manager = getAuthManager();
    authInvalidatedListener = new AuthInvalidatedListener(manager);
  }

  return authInvalidatedListener;
}

/**
 * Instantiates the global instances, and starts the listener.
 */
export function initializeAuth() {
  getAuthManager();
  const listener = getAuthInvalidatedListener();

  apiClient.requestFailed.add(listener.onRequestFailed);
  
  listener.start();
};