import { navigate } from 'raviger';
import { getOrigin } from './general';

/**
 * Takes an object and creates a query string from it. Will URI encode the keys and values so essentially anything can be passed to it.
 * example: const qs = makeQueryString({ 'key1': 'value1', 'key2': 3 }) // qs = ?key1=value1&key2=3
 * 
 * RETURNS: string
 */
export const makeQueryString = params => {
  const esc = encodeURIComponent;
  const keys = Object.keys(params);

  if (keys.length > 0)
    return '?' + keys.map(k => esc(k) + '=' + esc(params[k])).join('&');

  return '';
}

/**
 * Creates a query string for pagination options for the server-side. Values for the URL are automatically escaped.
 * exmaple: const qs = makePaginationQueryString(0, 50, 'name', 'desc') // qs = ?start=0&end=50&sortField=name&sortDirection=desc
 * 
 * RETURNS: string
 */
export const makePaginationQueryString = (start = null, end = null, sortField = null, sortDirection = null) => {
  const queryParams = {};
  if (start != null)
    queryParams['start'] = Math.floor(start);
  if (end != null)
    queryParams['end'] = Math.floor(end);

  if (sortField != null)
    queryParams['sort_field'] = sortField;
  if (sortDirection != null)
    queryParams['sort_direction'] = sortDirection;

  return makeQueryString(queryParams);
};

/**
 * Creates a new URLSearchParams instance for the current window URL.
 * 
 * RETURNS: URLSearchParams
 */
export const getSearchParams = () => new URLSearchParams(window.location.search);

/**
 * Click callback for app links.
 *
 * The created callback will only handle left mouse clicks.
 * If the href of the link is internal to the site in will use raviger's 'navigate' method for navigation,
 * this is to prevent unnecessary reloading of the page.
 *
 * If the href is not internal, the browser navigate to it on it's own.
 */
export const linkClickHandler = e => {
  const { button, currentTarget } = e;
  const { href, target } = currentTarget;

  if (!!href && button === 0) {
    const hrefOrigin = getOrigin(href);

    // Check is needed because we cannot use navigate for URL outside of our origin
    const isInternalUrl = !hrefOrigin || window.location.origin === hrefOrigin;
    if (!e.defaultPrevented && (!target || target === '_self') && isInternalUrl) {
      e.preventDefault();
      navigate(href);
    }
  }
}
