/*
  All classes used for storage purposes throughout the app, keep in mind that this storage is not in any way secure nor permanent!
  Every class should have three methods that define its public API:
  - set: takes one parameter, that is object to be saved
    get: returns current state of the saved object
    clear: clears the underlying storage
*/

//Imported objects etc
import { appEvents } from '../events/app_events'
import { APPLICATION_THEME } from '../utils/consts';
import { canGetThemeFromUserAgent, getThemeFromUserAgent } from '../utils/user_agent';
import IanaTimeZone from '../core/constructs/time/iana_time_zone';

import {
  eUnitsFlow,
  eUnitsDistance,
  eUnitsSpeed,
  eUnitsLoad,
  eUnitsWeight,
  eUnitsArea,
  eUnitsVolume,
  eUnitsPressure,
  eUnitsTemp,
  eUnitsLoLa,
  eUnitsDate,
  eUnitsTime,
  eUnitsFuelEco
} from '../utils/units'

//-------------------------------------------------------------------------------------------
//Data accessor classes
//-------------------------------------------------------------------------------------------

/*
Accessor class to the object representing current user selected privacy preferences.

Currently holding the following items:
  - cookiesConfirmed: bool, has user already confirmed cookies notifications or not
*/
export class PrivacyPreferences {
  constructor() {
    this.privacyPrefs = {};

    this.serialize = this.serialize.bind(this);
    this.deserialize = this.deserialize.bind(this);
    this.set = this.set.bind(this);
    this.get = this.get.bind(this);

    this.deserialize();

    if (this.privacyPrefs.cookiesConfirmed == null) {
      //initial state
      this.privacyPrefs = { cookiesConfirmed: false };
      this.serialize();
    }
  }

  serialize() {
    localStorage.setItem('cookiesConfirmed', this.privacyPrefs.cookiesConfirmed);
  }

  deserialize() {
    this.privacyPrefs.cookiesConfirmed = localStorage.getItem('cookiesConfirmed') === 'true';
  }

  set(privacyObj) {
    this.privacyPrefs = privacyObj;
    this.serialize();
  }

  get() {
    return this.privacyPrefs;
  }

  clear() {
    localStorage.removeItem('cookiesConfirmed');
  }
};

/*
Accessor class to the object representing current user's global device settings.

Currently holding the following items:
  - globalSamplingRate: currently set global sampling rate
*/
export class DeviceSettings {
  constructor() {
    this._deviceSettings = {};

    this.serialize = this.serialize.bind(this);
    this.deserialize = this.deserialize.bind(this);
    this.setGlobalSamplingRate = this.setGlobalSamplingRate.bind(this);
    this.getGlobalSamplingRate = this.getGlobalSamplingRate.bind(this);

    this.deserialize();

    if (!this._deviceSettings.globalSamplingRate) {
      //initial state
      this._deviceSettings = { globalSamplingRate: 5000 };
      this.serialize();
    }
  }

  serialize() {
    localStorage.setItem('globalSamplingRate', this._deviceSettings.globalSamplingRate);
  }

  deserialize() {
    this._deviceSettings.globalSamplingRate = parseInt(localStorage.getItem('globalSamplingRate'));
  }

  setGlobalSamplingRate(newRate) {
    this._deviceSettings.globalSamplingRate = newRate;
    this.serialize();
    appEvents.device_setings_changed.dispatch();
  }

  getGlobalSamplingRate() {
    return this._deviceSettings.globalSamplingRate;
  }

  clear() {
    localStorage.removeItem('globalSamplingRate');
  }
};

/*
Accessor class to the object representing current users display preferences.

Currently holding the following items:
*/
export class DisplayPreferences {
  constructor() {
    this.displayPrefs = {};
    this.sortingPrefs = {};

    this.serialize = this.serialize.bind(this);
    this.deserialize = this.deserialize.bind(this);

    this.setSelectedTheme = this.setSelectedTheme.bind(this);
    this.getSelectedTheme = this.getSelectedTheme.bind(this);

    this.setIsThemeAutoSet = this.setIsThemeAutoSet.bind(this);
    this.getIsThemeAutoSet = this.getIsThemeAutoSet.bind(this);

    this.setSorting = this.setSorting.bind(this);
    this.getSorting = this.getSorting.bind(this);

    this.getTimeFormat = this.getTimeFormat.bind(this);
    this.setTimeFormat = this.setTimeFormat.bind(this);

    this.getDateFormat = this.getDateFormat.bind(this);
    this.setDateFormat = this.setDateFormat.bind(this);

    this.getSelectedLogbookVessel = this.getSelectedLogbookVessel.bind(this);
    this.setSelectedLogbookVessel = this.setSelectedLogbookVessel.bind(this);

    this.getTimeZone = this.getTimeZone.bind(this);
    this.setTimeZone = this.setTimeZone.bind(this);
    this.setIsTimeZoneAutoSet = this.setIsTimeZoneAutoSet.bind(this);
    this.isTimeZoneAutoSet = this.isTimeZoneAutoSet.bind(this);

    this.clear = this.clear.bind(this);

    this.deserialize();
  }

  serialize() {
    localStorage.setItem('sortingPreferences', JSON.stringify(this.sortingPrefs));

    localStorage.setItem('selectedTheme', this.displayPrefs.selectedTheme);
    localStorage.setItem('selectedThemeAutoSet', this.displayPrefs.selectedThemeAutoSet);

    localStorage.setItem('timeFormat', this.displayPrefs.timeFormat);
    localStorage.setItem('dateFormat', this.displayPrefs.dateFormat);
    localStorage.setItem('timeZone', this.displayPrefs.timeZone);
    localStorage.setItem('timeZoneAutoSet', this.displayPrefs.timeZoneAutoSet);

    if (!!this.displayPrefs.selectedLogbookVessel)
      sessionStorage.setItem('selectedLogbookVessel', this.displayPrefs.selectedLogbookVessel);
    else
      sessionStorage.removeItem('selectedLogbookVessel');
  }

  deserialize() {
    const selectedThemeAutoSetValue = localStorage.getItem('selectedThemeAutoSet');
    const selectedThemeAutoSet = selectedThemeAutoSetValue !== null ? selectedThemeAutoSetValue === 'true' : true;
    this.displayPrefs.selectedThemeAutoSet = selectedThemeAutoSet;

    const selectedTheme = localStorage.getItem('selectedTheme');
    if (!!selectedTheme && !selectedThemeAutoSet)
      this.displayPrefs.selectedTheme = selectedTheme;
    else
      this.displayPrefs.selectedTheme = getThemeFromUserAgent() || APPLICATION_THEME.LXNAV_LIGHT;

    let sortingPrefs;
    try {
      sortingPrefs = JSON.parse(localStorage.getItem('sortingPreferences'));
    } catch {
      // TODO report error
    }

    if (typeof sortingPrefs === 'object' && sortingPrefs !== null)
      this.sortingPrefs = { ...sortingPrefs };
    else
      this.sortingPrefs = {};

    this.displayPrefs.timeFormat = eUnitsTime.EU_24H;
    const timeFormat = parseInt(localStorage.getItem('timeFormat'));
    for (const key in eUnitsTime) {
      if (eUnitsTime[key] === timeFormat) {
        this.displayPrefs.timeFormat = timeFormat;
        break;
      }
    }

    this.displayPrefs.dateFormat = eUnitsDate.EU_DDMMYY;
    const dateFormat = parseInt(localStorage.getItem('dateFormat'));
    for (const key in eUnitsDate) {
      if (eUnitsDate[key] === dateFormat) {
        this.displayPrefs.dateFormat = dateFormat;
        break;
      }
    }

    const timeZoneAutoSetValue = localStorage.getItem('timeZoneAutoSet');
    const timeZoneAutoSet = timeZoneAutoSetValue !== null ? timeZoneAutoSetValue === 'true' : true;
    this.displayPrefs.timeZoneAutoSet = timeZoneAutoSet;

    let timeZone = null;
    if (!timeZoneAutoSet) {
      timeZone = localStorage.getItem('timeZone');

      if (!!timeZone) {
        if (!IanaTimeZone.isValidZone(timeZone)) {
          appEvents.display_toast.dispatch({
            title: 'Invalid time zone',
            color: 'warning',
            iconType: 'alert',
            toastLifeTimeMs: 10000,
            text: "Could not use the stored time zone since it's invalid, your local time zone will be used instead."
          });

          timeZone = null;
        }
      }
    }

    const defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!!timeZone) {
      this.displayPrefs.timeZone = timeZone;
    } else {
      this.displayPrefs.timeZone = defaultTz;
      this.displayPrefs.timeZoneAutoSet = true;
    }

    const selectedLogbookVessel = sessionStorage.getItem('selectedLogbookVessel');
    if (!!selectedLogbookVessel)
      this.displayPrefs.selectedLogbookVessel = selectedLogbookVessel;
    else
      this.displayPrefs.selectedLogbookVessel = null;
  }

  setSorting(table, field, direction = 'asc') {
    if (!this.sortingPrefs[table])
      this.sortingPrefs[table] = {};

    if (direction !== 'asc' && direction !== 'desc')
      direction = 'asc';

    if (!!direction)
      this.sortingPrefs[table] = { field, direction };
    else
      delete this.sortingPrefs[table];

    this.serialize();
  }

  getSorting(table) {
    const tablePrefs = this.sortingPrefs[table];
    if (!tablePrefs)
      return null;

    let { field, direction } = tablePrefs;
    if (direction !== 'asc' && direction !== 'desc')
      direction = 'asc';

    return { field, direction };
  }

  setSelectedTheme(themeName) {
    this.displayPrefs.selectedTheme = themeName;
    this.displayPrefs.selectedThemeAutoSet = false;

    this.serialize();
    appEvents.display_preferences_changed.dispatch();
  }

  setIsThemeAutoSet(isAutoSet) {
    const newTheme = getThemeFromUserAgent();
    if (isAutoSet && !!newTheme)
      this.displayPrefs.selectedTheme = newTheme;

    this.displayPrefs.selectedThemeAutoSet = isAutoSet;
    this.serialize();

    appEvents.display_preferences_changed.dispatch();
  }

  getIsThemeAutoSet() {
    return canGetThemeFromUserAgent ? this.displayPrefs.selectedThemeAutoSet : false;
  }

  getSelectedTheme() {
    return this.displayPrefs.selectedTheme;
  }

  getTimeFormat() {
    const {
      timeFormat
    } = this.displayPrefs;

    return Object.values(eUnitsTime).includes(timeFormat) ? timeFormat : eUnitsTime.EU_24H;
  }

  setTimeFormat(timeFormat) {
    this.displayPrefs.timeFormat = parseInt(timeFormat);
    this.serialize();
  }

  getDateFormat() {
    const {
      dateFormat
    } = this.displayPrefs;

    return Object.values(eUnitsDate).includes(dateFormat) ? dateFormat : eUnitsDate.EU_DDMMYY;
  }

  setDateFormat(dateFormat) {
    this.displayPrefs.dateFormat = parseInt(dateFormat);
    this.serialize();
  }

  getTimeZone() {
    return this.displayPrefs.timeZone;
  }

  setTimeZone(tzName) {
    this.displayPrefs.timeZone = tzName;
    this.displayPrefs.timeZoneAutoSet = false;
    this.serialize();
  }

  setIsTimeZoneAutoSet(isAutoSet) {
    if (isAutoSet)
      this.displayPrefs.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.displayPrefs.timeZoneAutoSet = isAutoSet;
    this.serialize();
  }

  isTimeZoneAutoSet() {
    return this.displayPrefs.timeZoneAutoSet;
  }

  getSelectedLogbookVessel() {
    return this.displayPrefs.selectedLogbookVessel;
  }

  setSelectedLogbookVessel(vesselId) {
    this.displayPrefs.selectedLogbookVessel = vesselId;
    this.serialize();
  }

  clear() {
    this.deserialize();
    appEvents.display_preferences_changed.dispatch();
  }
};

export class UnitsPreferences {
  constructor() {
    this.unitsPrefs = {};

    this.serialize = this.serialize.bind(this);
    this.deserialize = this.deserialize.bind(this);

    this.setUnitsFlow = this.setUnitsFlow.bind(this);
    this.getUnitsFlow = this.getUnitsFlow.bind(this);

    this.setUnitsDistance = this.setUnitsDistance.bind(this);
    this.getUnitsDistance = this.getUnitsDistance.bind(this);

    this.setUnitsDepth = this.setUnitsDepth.bind(this);
    this.getUnitsDepth = this.getUnitsDepth.bind(this);

    this.setUnitsSpeed = this.setUnitsSpeed.bind(this);
    this.getUnitsSpeed = this.getUnitsSpeed.bind(this);

    this.setUnitsWeight = this.setUnitsWeight.bind(this);
    this.getUnitsWeight = this.getUnitsWeight.bind(this);

    this.setUnitsArea = this.setUnitsArea.bind(this);
    this.getUnitsArea = this.getUnitsArea.bind(this);

    this.setUnitsVolume = this.setUnitsVolume.bind(this);
    this.getUnitsVolume = this.getUnitsVolume.bind(this);

    this.setUnitsPressure = this.setUnitsPressure.bind(this);
    this.getUnitsPressure = this.getUnitsPressure.bind(this);

    this.setUnitsTemp = this.setUnitsTemp.bind(this);
    this.getUnitsTemp = this.getUnitsTemp.bind(this);

    this.setUnitsLoLa = this.setUnitsLoLa.bind(this);
    this.getUnitsLoLa = this.getUnitsLoLa.bind(this);

    this.setUnitsFuelEco = this.setUnitsFuelEco.bind(this);
    this.getUnitsFuelEco = this.getUnitsFuelEco.bind(this);

    this.setUnitsAlt = this.setUnitsAlt.bind(this);
    this.getUnitsAlt = this.getUnitsAlt.bind(this);

    this.deserialize();
  }

  //---------------------------------------------------------------------------------------

  setUnitsFlow(unitsFlow) {
    this.unitsPrefs.unitsFlow = unitsFlow;
    this.serialize();
  }

  setUnitsDistance(unitsDistance) {
    this.unitsPrefs.unitsDistance = unitsDistance;
    this.serialize();
  }

  setUnitsDepth(unitsDepth) {
    this.unitsPrefs.unitsDepth = unitsDepth;
    this.serialize();
  }

  setUnitsSpeed(unitsSpeed) {
    this.unitsPrefs.unitsSpeed = unitsSpeed;
    this.serialize();
  }

  setUnitsWeight(unitsWeight) {
    this.unitsPrefs.unitsWeight = unitsWeight;
    this.serialize();
  }

  setUnitsArea(unitsArea) {
    this.unitsPrefs.unitsArea = unitsArea;
    this.serialize();
  }

  setUnitsVolume(unitsVolume) {
    this.unitsPrefs.unitsVolume = unitsVolume;
    this.serialize();
  }

  setUnitsPressure(unitsPressure) {
    this.unitsPrefs.unitsPressure = unitsPressure;
    this.serialize();
  }

  setUnitsTemp(unitsTemp) {
    this.unitsPrefs.unitsTemp = unitsTemp;
    this.serialize();
  }

  setUnitsLoLa(unitsLoLa) {
    this.unitsPrefs.unitsLoLa = unitsLoLa;
    this.serialize();
  }

  setUnitsFuelEco(unitsFuelEco) {
    this.unitsPrefs.unitsFuelEco = unitsFuelEco;
    this.serialize();
  }

  setUnitsAlt(unitsAlt) {
    this.unitsPrefs.unitsAlt = unitsAlt;
    this.serialize();
  }

  //---------------------------------------------------------------------------------------

  getUnitsFlow() {
    return this.unitsPrefs.unitsFlow;
  }

  getUnitsDistance() {
    return this.unitsPrefs.unitsDistance;
  }

  getUnitsDepth() {
    return this.unitsPrefs.unitsDepth;
  }

  getUnitsSpeed() {
    return this.unitsPrefs.unitsSpeed;
  }

  getUnitsWeight() {
    return this.unitsPrefs.unitsWeight;
  }

  getUnitsArea() {
    return this.unitsPrefs.unitsArea;
  }

  getUnitsVolume() {
    return this.unitsPrefs.unitsVolume;
  }

  getUnitsPressure() {
    return this.unitsPrefs.unitsPressure;
  }

  getUnitsTemp() {
    return this.unitsPrefs.unitsTemp;
  }

  getUnitsLoLa() {
    return this.unitsPrefs.unitsLoLa;
  }

  getUnitsFuelEco() {
    return this.unitsPrefs.unitsFuelEco;
  }

  getUnitsAlt() {
    return this.unitsPrefs.unitsAlt;
  }

  //---------------------------------------------------------------------------------------

  serialize() {
    localStorage.setItem('unitsFlow', this.unitsPrefs.unitsFlow);
    localStorage.setItem('unitsDistance', this.unitsPrefs.unitsDistance);
    localStorage.setItem('unitsDepth', this.unitsPrefs.unitsDepth);
    localStorage.setItem('unitsSpeed', this.unitsPrefs.unitsSpeed);
    localStorage.setItem('unitsLoad', this.unitsPrefs.unitsLoad);
    localStorage.setItem('unitsWeight', this.unitsPrefs.unitsWeight);
    localStorage.setItem('unitsArea', this.unitsPrefs.unitsArea);
    localStorage.setItem('unitsVolume', this.unitsPrefs.unitsVolume);
    localStorage.setItem('unitsPressure', this.unitsPrefs.unitsPressure);
    localStorage.setItem('unitsTemp', this.unitsPrefs.unitsTemp);
    localStorage.setItem('unitsLoLa', this.unitsPrefs.unitsLoLa);
    localStorage.setItem('unitsFuelEco', this.unitsPrefs.unitsFuelEco);
    localStorage.setItem('unitsAlt', this.unitsPrefs.unitsAlt);

    appEvents.user_units_changed.dispatch();
  }

  deserialize() {
    let itm = localStorage.getItem('unitsFlow');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsFlow = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsFlow = eUnitsFlow.EU_Lph;
    }


    itm = localStorage.getItem('unitsDistance');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsDistance = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsDistance = eUnitsDistance.EU_Km;
    }


    itm = localStorage.getItem('unitsDepth');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsDepth = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsDepth = eUnitsDistance.EU_M;
    }


    itm = localStorage.getItem('unitsSpeed');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsSpeed = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsSpeed = eUnitsSpeed.EU_Kph;
    }


    itm = localStorage.getItem('unitsLoad');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsLoad = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsLoad = eUnitsLoad.EU_KgM2;
    }


    itm = localStorage.getItem('unitsWeight');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsWeight = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsWeight = eUnitsWeight.EU_Kg;
    }


    itm = localStorage.getItem('unitsArea');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsArea = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsArea = eUnitsArea.EU_M2;
    }


    itm = localStorage.getItem('unitsVolume');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsVolume = parseInt(localStorage.getItem('unitsVolume'));
    }
    else {
      this.unitsPrefs.unitsVolume = eUnitsVolume.EU_Liter;
    }


    itm = localStorage.getItem('unitsPressure');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsPressure = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsPressure = eUnitsPressure.EU_Mbar;
    }


    itm = localStorage.getItem('unitsTemp');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsTemp = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsTemp = eUnitsTemp.EU_Celsius;
    }


    itm = localStorage.getItem('unitsLoLa');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsLoLa = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsLoLa = eUnitsLoLa.EU_DDMMSS;
    }

    itm = localStorage.getItem('unitsFuelEco');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsFuelEco = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsFuelEco = eUnitsFuelEco.EU_LP100Km;
    }

    itm = localStorage.getItem('unitsAlt');
    if ((itm !== null) && (itm !== undefined)) {
      this.unitsPrefs.unitsAlt = parseInt(itm);
    }
    else {
      this.unitsPrefs.unitsAlt = eUnitsDistance.EU_M;
    }
  }
};


//-------------------------------------------------------------------------------------------
//Exported variables used to access application storage
//-------------------------------------------------------------------------------------------

//global object used to access PrivacyPreferences
export let privacyPreferencesStorage;

//global object used to access DisplayPreferences
export let displayPreferencesStorage;

//global object used to access UnitsPreferences
export let unitsPreferencesStorage;

//global object used to access DeviceSettings
export let deviceSettingsStorage;


//-------------------------------------------------------------------------------------------
//Other
//-------------------------------------------------------------------------------------------

//method used to initialize all objects that represent application storage
//should be called once
export function initializeAppStorage() {
  unitsPreferencesStorage = new UnitsPreferences();
  displayPreferencesStorage = new DisplayPreferences();
  privacyPreferencesStorage = new PrivacyPreferences();
  deviceSettingsStorage = new DeviceSettings();
}
