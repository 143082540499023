import HttpException from '../api/base/http_exception';
import { getUserVessels } from '../api/vessels_api';
import { getAuthManager } from '../core/authentication/global';
import { appEvents } from '../events/app_events';
import { MSEC2SEC } from '../utils/consts';
import { LiveDataReport } from '../utils/vessel/live_data';

const REFRESH_PERIOD = 5000;

let instance = null;

/**
 * @returns {LiveDataService} The global live data service instance
 */
export function getGlobalInstance() {
  return instance;
};

export function initializeGlobalDataService() {
  instance = new LiveDataService(REFRESH_PERIOD, appEvents.global_data_loaded.dispatch);

  const authManager = getAuthManager();
  appEvents.user_changed.add(() => {
    if (authManager.isSignedIn)
      instance.start();
    else
      instance.stop();
  });

  if (authManager.isSignedIn)
    instance.start();

  return instance;
}

export class Data {
  constructor(vesselsObject, liveDataReportsObject) {
    this._vesselsLookup = vesselsObject;
    this._liveDataReportLookup = liveDataReportsObject;

    this.getLiveDataLookup = this.getLiveDataLookup.bind(this);
    this.getAllLiveData = this.getAllLiveData.bind(this);
    this.getLiveData = this.getLiveData.bind(this);
    this.getVessel = this.getVessel.bind(this);
    this.getVessels = this.getVessels.bind(this);
    this.getVesselsLookup = this.getVesselsLookup.bind(this);

    this.getWarningsForVessel = this.getWarningsForVessel.bind(this);
    this.getWarnings = this.getWarnings.bind(this);
    this.getTotalWarningCount = this.getTotalWarningCount.bind(this);
  }

  getVessels() {
    return Object.values(this._vesselsLookup);
  }

  getVesselsLookup() {
    return { ...this._vesselsLookup };
  }

  getVessel(id) {
    return this._vesselsLookup[id];
  }

  getAllLiveData() {
    return Object.values(this._liveDataReportLookup);
  }

  getLiveDataLookup() {
    return { ...this._liveDataReportLookup };
  }

  getLiveData(id) {
    return this._liveDataReportLookup[id];
  }

  getWarningsForVessel(id) {
    const ldr = this.getLiveData(id);
    if (!ldr)
      return [];

    return ldr.getWarnings() || [];
  }

  getWarnings() {
    let arr = [];
    for (const id in this._liveDataReportLookup) {
      const ldr = this._liveDataReportLookup[id];

      const warnings = ldr.getWarnings();
      if (!!warnings) {
        arr.push({
          vessel: this.getVessel(id),
          warnings: warnings.filter(w => !w.IsDismissed)
        });
      }
    }

    return arr;
  }

  getTotalWarningCount() {
    return this.getWarnings().reduce((acc, x) => x.warnings.length + acc, 0);
  }
}

// TODO factor out scheduling?
// TODO Do not directly invoke the display_ toast event; but a more service specific signal that then raises that other signal
// TODO rename this stuff?
const MAX_REFRESH_FACTOR = 5;
export class LiveDataService {
  constructor(refreshPeriod, onLoadedCallback) {
    this._refreshPeriod = refreshPeriod;
    this._timeoutHandle = null;
    this._isRunning = false;
    this._hasLoadedData = false;

    this._refreshFactor = 1;
    this._onLoaded = onLoadedCallback;

    this._dataInstance = new Data({}, {});

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.isRunning = this.isRunning.bind(this);

    this.getData = this.getData.bind(this);
    this.reloadData = this.reloadData.bind(this);

    this._scheduleNextLoad = this._scheduleNextLoad.bind(this);
    this._onRefreshPeriodElapsed = this._onRefreshPeriodElapsed.bind(this);
  }

  start() {
    if (!this._isRunning) {
      this._isRunning = true;
      this._onRefreshPeriodElapsed();
    }
  }

  stop() {
    window.clearTimeout(this._timeoutHandle);
    this._timeoutHandle = null;
    this._isRunning = false;
    this._hasLoadedData = false;
  }

  isRunning() {
    return this._isRunning;
  }

  _scheduleNextLoad(applyDelay) {
    if (applyDelay)
      this._refreshFactor += this._refreshFactor < MAX_REFRESH_FACTOR ? 1 : 0;
    else
      this._refreshFactor = 1;

    const nextLoad = this._refreshPeriod * this._refreshFactor;
    this._timeoutHandle = setTimeout(this._onRefreshPeriodElapsed, nextLoad);

    return nextLoad;
  }

  isDataReady() {
    return this._isRunning && this._hasLoadedData;
  }

  getData() {
    return this._dataInstance;
  }

  async reloadDataIfNotReady() {
    if (this.isDataReady())
      return;

    return this.reloadData();
  }

  async reloadData() {
    const data = await this._fetchData();
    this._dataInstance = data;
    this._hasLoadedData = true;

    if (this._isRunning)
      this._onLoaded();
  }

  async _fetchData() {
    const vessels = await getUserVessels();

    const ids = [];
    const newVesselsLookUp = {};
    for (const v of vessels) {
      newVesselsLookUp[v.id] = v;
      ids.push(v.id);
    }

    let newLiveDataLookup = {};
    if (ids.length > 0) 
      newLiveDataLookup = await LiveDataReport.loadBatched(ids);

    return new Data(newVesselsLookUp, newLiveDataLookup);
  }

  async _onRefreshPeriodElapsed() {
    let error = null;
    try {
      await this.reloadData();
    } catch (e) {
      error = e;
    }

    // Check if the service was stopped while waiting for the request to complete
    if (!this._isRunning)
      return;

    if (!error) {
      this._scheduleNextLoad(false);
    } else {
      // TODO eventually remove
      if (error instanceof HttpException && error.statusCode === 401) {
        this.stop();
        return;
      }

      const nextLoad = this._scheduleNextLoad(true);
      appEvents.display_toast.dispatch({
        title: 'Error',
        color: 'warning',
        iconType: 'alert',
        text: `Failed to refresh data! Retrying in ${nextLoad * MSEC2SEC} seconds.`
      });
    }
  }
}