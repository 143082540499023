/*
  Collection of functions for manipulating/reading user notifications.
  NOTE: All API calls (except for 'getUserNotifications') from this file require that the user is an admin.
*/

import { apiClient as client } from './base/api_client';

import { makePaginationQueryString } from '../utils/web';

/*
  NOTIFICATION_OBJ = {
    id: number, // Identifier
    message: string, // Body text
    title: string, // Title text
    isDisabled: boolean // If disabled the notification will not be shown to users on login
  };
*/

/*
  Fetches the notifications for the current user.
  Returns a Promise containing the user's notifications (NOTIFICATION_OBJ[]).
*/
export function getUserNotifications(userId = null) {
  const userPath = userId != null ? userId : 'me';

  return client.get(`api/users/${userPath}/notifications`);
}

/*
  Fetches all notifications from the server-side. Optionally supports pagination and sorting of the notifications.
  Returns a Promise containing the requested notifications (NOTIFICATION_OBJ[]).
*/
export async function getNotifications(start = null, end = null, sortField = null, sortDirection = null) {
  const qs = makePaginationQueryString(start, end, sortField, sortDirection);

  const {
    count,
    items
  } = await client.get(`api/notifications${qs}`);

  return {
    count,
    items: items.map(({ is_disabled, ...rest }) => ({ isDisabled: is_disabled, ...rest }))
  };
}

/*
  Creates a new notification.
  Returns a Promise with the newly created notification (NOTIFICATION_OBJ).
*/
export function createNotification(title, message, isDisabled = false) {
  return client.post(
    'api/notifications',
    {
      title,
      message,
      is_disabled: isDisabled
    }
  );
}

/*
  Updates the given notification.
  Returns a Promise with the updated notification (NOTIFICATION_OBJ).

  SUPPORTED FIELDS:
  - isDisabled: boolean,
  - title: string,
  - message: string
*/
export function patchNotification(params, id) {
  const {
    isDisabled,
    ...rest
  } = params;

  const data = { ...rest };
  if (isDisabled !== undefined)
    data.is_disabled = isDisabled;

  return client.patch(
    `api/notifications/${id}`,
    data
  );
}

/*
  Changes the disabled status of the given notification.
  Returns a Promise with the updated notificaion (NOTIFICATION_OBJ). 
*/
export function changeIsNotificationDisabled(isDisabled, id) {
  return patchNotification({ isDisabled }, id);
}

/*
  Deletes the given notification.
  Returns a Promise with no result.
*/
export function deleteNotification(id) {
  return client.delete(`api/notifications/${id}`);
}