import React from 'react';
import { useEffect, useState } from 'react';
import { appEvents } from '../../events/app_events';

/**
 * Function used to create a HOC that allows react components easy access to the given authentication class instances.
 * 
 * @param {AuthManager} manager 
 * @param {AuthInvalidatedListener} invalidatedListener 
 * 
 * The passed component will be instantiated with the following props:
 * -user: User? // currently authenticated user, will be updated on change 
 * -isMonitoringAuth: boolean // if the auth invalidated listener monitoring for failed requests
 * -startMonitoringAuth: () => void // start monitoring failed requests
 * -stopMonitoringAuth: () => void // stops monitoring failes requests
 */
export const withAuth = (manager, invalidatedListener) => Component => props => {
  const [user, setUser] = useState(() => manager.currentUser);
  const [isMonitioring, setIsMonitoring] = useState(invalidatedListener.isRunning);

  useEffect(() => {
    const cb = () => {
      const newUser = manager.currentUser;
      setUser(newUser);
    };

    appEvents.user_changed.add(cb);

    return () => {
      appEvents.user_changed.remove(cb);
    };
  }, []);

  useEffect(() => {
    const cb = () => {
      const newState = invalidatedListener.isRunning;
      setIsMonitoring(newState);
    };

    invalidatedListener.addStatusChangedListener(cb);

    return () => {
      invalidatedListener.removeStatusChangedListener(cb);
    };
  }, []);

  return (
    <Component
      user={user}
      isMonitoringAuth={isMonitioring}
      startMonitoringAuth={invalidatedListener.start}
      stopMonitoringAuth={invalidatedListener.stop}
      {...props}
    />
  );
};
