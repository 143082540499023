class InvalidUserError extends Error {
  constructor(user, message) {
    super(message);

    this._user = user;
  }

  get user() {
    return this._user;
  }
}

export default InvalidUserError;