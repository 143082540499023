import React from 'react';
import { AUTH_LEVEL } from '../../utils/consts';
import RedirectToSignIn from './redirect_to_sign_in';

export const withAuthGuard = (Component, requiredAuthLevel, redirectAfter) => ({ user, ...rest }) => {
  let shouldReject = false;
  switch (requiredAuthLevel) {
    case AUTH_LEVEL.SIGNED_IN:
      shouldReject = !user;
      break;

    case AUTH_LEVEL.ADMINISTRATOR:
      shouldReject = !user || !user.isAdmin;
      break;

    default:
      break;
  }

  if (shouldReject) {
    let redirectAfterStr = null;
    if (typeof redirectAfter === 'function')
      redirectAfterStr = redirectAfter(user);
    else
      redirectAfterStr = redirectAfter;

    return (<RedirectToSignIn redirectAfter={redirectAfterStr} />);
  }

  return (<Component user={user} {...rest} />);
};
