/*
  Renders the application logo.

  Public API exposed via props:
  - light (optional): boolean, // Light version if true; dark version otherwise. Defaults to true.
  - hideLabel (optional): boolean // If true the label will not be shown. Defaults to false.
*/

import React from 'react';

import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiTextColor,
  EuiImage
} from '@elastic/eui';

import lxnav_logo_light from '../../assets/images/lxnav_logo_light.svg';
import lxnav_logo_dark from '../../assets/images/lxnav_logo_dark.svg';

const ApplicationLogo = ({
  light = true,
  hideLabel = false,
}) => {
  const txtColor = light ? 'ghost' : 'default';
  const logo = light ? lxnav_logo_light : lxnav_logo_dark;

  return (
    <EuiFlexItem grow={false}>
      <EuiFlexGroup
        responsive={false}
        gutterSize='s'
        alignItems='center'
      >
        <EuiFlexItem grow={false}>
          <EuiImage
            url={logo}
            size='60px'
            alt='LXNAV'
          />
        </EuiFlexItem>

        {!hideLabel && (
          <EuiFlexItem>
            <h3 style={{ fontSize: '20px' }}>
              <EuiTextColor color={txtColor}>
                SMART<b>GPS</b>
              </EuiTextColor>
            </h3>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};

export default ApplicationLogo;