import { navigate } from 'raviger';

import { makeQueryString } from '../../utils/web';

export const AUTHENTICATION__REDIRECT_AFTER = 'redirectAfter';

const getCurrentUrl = () => {
  const {
    pathname,
    search,
    hash
  } = window.location;

  return `${pathname}${search}${hash}`;
};

/* Creates an URL to the sign-in page that will redirect the user after a succesfull sign-in to the given url.
If the url is ommited the user will be redirected to the location of the browser at the time of calling this function. */
export const createSignInWithRedirectAfterUrl = (url = null) => {
  url = !!url ? url : getCurrentUrl();

  const query = url !== '/' ? makeQueryString({ [AUTHENTICATION__REDIRECT_AFTER]: url }) : '';
  return '/sign-in' + query;
};

export const navigateToSignInWithRedirectAfter = (url = null, replace = undefined) => {
  url = !!url ? url : getCurrentUrl();

  const signInUrl = createSignInWithRedirectAfterUrl(url);
  navigate(signInUrl, replace);
};
