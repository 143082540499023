/*
  Link component to be used around the application. Wraps a EuiLink but will use 'raviger' to navigate to relative links.
  Use this component instead of 'EuiLink' and raviger's 'Link' component as it will fit the style still work as expected.

  Public API exposed via props:
  - href (optional): string, // Same as the 'a' tag's href attribute
  - target (optional): string, // Same as the 'a' tag's target attribute
  - onClick (optional): Event => void // On click handler for the link
  - unstyled (optional): boolean // If true the link will be rendered without any intrinsic styling; defaults to false
  - className (optional): string // Additional CSS class to be applied to link
  
  (Any other props are passed to the underlying EuiLink)
*/

import React, { useCallback } from 'react';
import { useBasePath } from 'raviger';
import cx from 'classnames';

import { linkClickHandler } from '../../utils/web';

import { EuiLink } from '@elastic/eui';

const ApplicationLink = ({
  href,
  target,
  onClick,
  unstyled = false,
  className,
  ...props
}) => {
  const basePath = useBasePath();

  if (!!href)
    href = href.substring(0, 1) === '/' ? basePath + href : href;

  const onClickHandler = useCallback(e => {
    if (!!onClick)
      onClick(e);

    linkClickHandler(e);
  }, [onClick]);

  const composedClassName = cx(
    className,
    'lxnavApplicationLink', {
      'lxnavApplicationLink--unstyled': unstyled
    }
  );

  return (
    <EuiLink
      {...props}
      href={href}
      target={target}
      external={false}
      className={composedClassName}
      onClick={onClickHandler}
    />
  );
};

export default ApplicationLink;