/**
 * Returns the first paramter passed.
 *
 * @param {*} x anything
 * @returns {*} anything
 */
export const identity = x => x;

/**
 * Ensures the given value is within the min-max range.
 * 
 * @param {number} value the value to constrain
 * @param {number} min allowed minimum value
 * @param {number} max allowed maximum value
 * @returns {number} min if value is smaller than min; max if value is larger than max; passed value otherwise
 */
export const constrain = (value, min, max) => Math.max(min, Math.min(value, max));

/**
 * Linearly scales the value from its current numeric range to the target range.
 * 
 * @param {number} value value to transform
 * @param {number} valueMin minimum of value's range
 * @param {number} valueMax maximum of value's range
 * @param {number} targetMin target range minimum
 * @param {number} targetMax target range maximum
 * @returns {number} the transform value
 */
export function convertToRange(value, valueMin, valueMax, targetMin = 0, targetMax = 1) {
  return (constrain(value, valueMin, valueMax) - valueMin) / (valueMax - valueMin) * (targetMax - targetMin) + targetMin;
}

/**
 * Internal function used to perform a binary search on an array of elements.
 * The value of the elements is determined at function runtime by invoking the specified callback.
 * 
 * @param {Array<T>} array array of elements to check against,
 * the array must be sorted in such a way that calling the callback over each element in-order would create an array of descendingly ordered numbers
 * @param {number} lowerIndex lower bound of the search space
 * @param {numer} upperIndex upper bound of the search space
 * @param {(T) => number} distFunc callback invoked with elements to determine their distance to the searched value
 * @returns {number} index of the element closest to the searched value
 */
function binSearchBestMatch(array, lowerIndex, upperIndex, distFunc) {
  const median = Math.floor((upperIndex + lowerIndex) / 2);
  if (upperIndex > lowerIndex) {
    const leftRes = Math.abs(distFunc(array[median]));
    const rightRes = Math.abs(distFunc(array[median + 1]));

    // if a perfect match is found return it
    if (leftRes === 0)
      return median;
    else if (rightRes === 0)
      return median + 1;

    let nextLower, nextUpper;
    if (leftRes <= rightRes) {
      nextLower = lowerIndex;
      nextUpper = median;
    } else {
      nextLower = median + 1;
      nextUpper = upperIndex;
    }

    return binSearchBestMatch(array, nextLower, nextUpper, distFunc);
  } else {
    return median;
  }
}

/**
 * Finds the index of the element that is the closest match for the given distance function. It uses binary searching and is thus very fast.
 * 
 * @param {Array<T>} array array of elements to check against,
 * the array must be sorted in such a way that calling the callback over each element in-order would create an array of descendingly ordered numbers
 * @param {(T) => number} distFunc callback invoked with elements to determine their distance to the searched value
 * @param {numer} maxDistance maximum allowed distance for the 
 * @returns {number} index of the element closest to the searched value if it is still within the maximum distance, if it isn't -1 is returned
 */
export function determineClosest(array, distFunc, maxDistance = -1) {
  const bmIndex = binSearchBestMatch(array, 0, array.length - 1, distFunc);

  if (maxDistance < 0 || Math.abs(distFunc(array[bmIndex])) <= maxDistance) // This is the best fit we could find, check to see it its within the limit
    return bmIndex;
  else
    return -1;
}

/**
 * Attempts to parse and return the given JSON, if it fails the second paramater is returned.
 * 
 * @param {string} json JSON to desirialize
 * @param {*} defaultValue value to return
 * @returns {*} desirialized JSON value if successful, and defaultValue otherwise
 */
export function tryParseJson(json, defaultValue = undefined) {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultValue;
  }
}

/**
 * Function used to determine whether a string is comprised solely of digits.
 * 
 * @param {string} str the string to check
 * @returns {boolean} true if the string is comprised solely of digits and has a length greater than 0; false otherwsie
 */
export function isDigitsOnly(str) {
  return /^[0-9]+$/.test(str);
}

/**
 * Determines whather the passed value is a string or a String instance.
 * 
 * @param {*} val the value to check
 * @returns {boolean} true if the passed value is a sring or String instance; false otherwise
 */
export function isString(val) {
  if (typeof val === 'string' || val instanceof String)
    return true;

  return false;
}

/**
 * Function used to test whether a string is a valid email address.
 * 
 * @param {string} str the string to check
 * @returns {boolean} true whether the string is a valid email address; false otherwise
 */
export function isEmail(str) {
  /* eslint-disable no-control-regex */
  return /^(?:[a-z0-9!#$%&'"*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'"*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(str);
}

/**
 * Returns the origin string from a URL string.
 * EXAMPLE: getOrigin("https://something.lxnav.com:9999/some/url?someQueryParam=someValue#someAnchor") // => https://something.lxnav.com:9999
 * 
 * @param {string} str url string from whivh to extract the origin 
 * @returns {string|null} returns the origin string if successful, and null oterwise
 */
export function getOrigin(str) {
  let url = null;
  try {
    url = new URL(str);
  } catch {
    return null;
  }

  return url.origin !== 'null' ? url.origin: null;
}

/**
 * Removes all whitespace from the beggining and end of a string. Whitespace within the string is replaced with a single space character,
 * EXAMPLE: normalizeWhiteSpace('  aa     bb   ') // 'aa bb'
 * 
 * @param {string} str input string
 * @returns {string} the transformed string
 */
export function normalizeWhiteSpace(str) {
  return str.trim().replace(/\s+/g, ' ');
}

/**
 * Converts a unix timestamp to a Date object.
 * 
 * @param {number} unix unix timestamp
 * @returns {Date} date object representing the timestamp 
 */
export function unixToDate(unix) {
  return new Date(unix * 1000);
}

/**
 * Converts a date object to a unix timestamp.
 * WARNING: Since Date uses miliseconds internally it's rounded to the closest UNIX time!  
 * 
 * @param {Date} date date to convert
 * @returns {number} unix timestamp
 */
export function dateToUnix(date) {
  return Math.round(date.getTime() / 1000);
}

/**
 * Function used to paginate an array of items.
 * 
 * @param {array} items items to paginate
 * @param {number} pageIndex index of page
 * @param {number} pageSize size of pages 
 * @returns {array} paginated array of items
 */
export function paginate(items, pageIndex, pageSize) {
  const start = pageIndex * pageSize;
  const end = start + pageSize;

  return items.slice(start, end);
}

/**
 * Function returns distance between two geographical points in meters.
 * 
 * @param {number} lon1 longitude of the first point (in radians) 
 * @param {number} lat1 latitude of the first point (in radians)
 * @param {number} lon2 longitude of the second point (in radians) 
 * @param {number} lat2 latitude of the second point (in radians)
 * @returns {number} distance between the point in meters
 */
export function getGeoDistance(lon1, lat1, lon2, lat2) {
  const R = 6371.; // Radius of the earth in km

  const dLat = lat2 - lat1; // deg2rad below
  const dLon = lon2 - lon1;

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c) * 1000.; // Distance in m
}