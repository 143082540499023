/**
 * Class is used to monitor failed API requests. This is to find out whether the request was rejected due to an expired/missing authentication cookie.
 */
class AuthInvalidatedListener {
  constructor(authManager) {
    this._authManager = authManager;
    this._isRunning = false;

    this._statusChangedListeners = [];

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.addStatusChangedListener = this.addStatusChangedListener.bind(this);
    this.removeStatusChangedListener = this.removeStatusChangedListener.bind(this);
    this.onRequestFailed = this.onRequestFailed.bind(this);
    this._changeIsRunning = this._changeIsRunning.bind(this);
  }

  /**
   * Is the listener currently monitoring requests.
   */
  get isRunning() {
    return this._isRunning;
  }

  /**
   * Start monitoring requests. If the listener is already running, calling this method will have no effect.
   */
  start() {
    this._changeIsRunning(true);
  }

  /**
   * Stop monitoring requests. If the listener is already not running, calling this method will have no effect.
   */
  stop() {
    this._changeIsRunning(false);
  }

  /**
   * Queues the passed callback for invocation when the listener is started/stopped.
   * 
   * @param {() => void} listener Cllback to invoke on a status change
   */
  addStatusChangedListener(listener) {
    this._statusChangedListeners.push(listener);
  }

  /**
   * Removed the passed callback from the invocation queue when the listener is started/stopped.
   * 
   * @param {() => void} listener Cllback to invoke on a status change
   */
  removeStatusChangedListener(listener) {
    this._statusChangedListeners = this._statusChangedListeners.filter(l => l !== listener);
  }

  _changeIsRunning(newValue) {
    const prevVal = this._isRunning;

    this._isRunning = newValue;

    if (prevVal !== this._isRunning) {
      for (const l of this._statusChangedListeners)
        l();
    }
  }

  async onRequestFailed(response) {
    if (!this._isRunning)
      return;

    if (response.status !== 401)
      return;

    await this._authManager.signOut();
  }
};

export default AuthInvalidatedListener;
