import { once } from 'lodash';

/* Animates a scrolling effect to the selected element */
export function vScrollElement(domEl, scrollTop, animationLength) {
  const totalDiff = scrollTop - domEl.scrollTop;
  const speed =  animationLength !== 0 ? totalDiff / animationLength : totalDiff;

  let prevTs = null;
  return new Promise((resolve, reject) => {
    const animFunc = time => {
      let timeDiff = 0;
      if (prevTs !== null)
        timeDiff = time - prevTs;

      if (timeDiff < 1)
        timeDiff = 1;

      let newScrollPos = domEl.scrollTop + timeDiff * speed;

      const reachedMin = newScrollPos <= 0 && speed < 0;
      const reachedMax = domEl.scrollHeight - Math.abs(newScrollPos) <= domEl.clientHeight && speed > 0;
      const reachedTarget = (newScrollPos >= scrollTop && speed > 0) || (newScrollPos <= scrollTop && speed < 0);
      if (reachedMin || reachedMax) {
        domEl.scrollTop = reachedMax ? domEl.scrollHeight - domEl.clientHeight : 0;
        resolve(); 
      } else if (reachedTarget) {
        domEl.scrollTop = scrollTop;
        resolve();
      } else {
        domEl.scrollTop = newScrollPos;
        prevTs = time;
        window.requestAnimationFrame(animFunc);
      }
    };

    window.requestAnimationFrame(animFunc);
  });
}

export function removeDomElement(element) {
  const { parentNode } = element;
  if (!!parentNode)
    parentNode.removeChild(element);
}

export function waitImageLoad(imgElement) {
  if (imgElement.complete)
    return Promise.resolve();

  return new Promise((resolve) => {
    const onImgLoaded = once(() => {
      imgElement.removeEventListener('load', onImgLoaded);

      resolve();
    });

    imgElement.addEventListener('load', onImgLoaded);
  });
};
