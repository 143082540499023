import { Signal } from 'signals';

import HttpException from './http_exception';

/**
 * Class used to abstract API calls to the backend.
 * Public API:
 *   -constructor(timeout: number, baseUrl: string): ApiClient
 *
 *   All of the below methods return a Promise containing the server's response, if the response contains JSON it is automatically parsed to an object.
 *   Similarly, if the methods accepting data are passed an object it is automatically converted to JSON before being sent to the server.
 *
 *   -sendRequest(path: string, method: string, data?: any): Promise<any>
 *   -get(path: string): Promise<any>
 *   -post(path: string, data?: any): Promise<any>
 *   -put(path: string, data?: any): Promise<any>
 *   -patch(path: string, data?: any): Promise<any>
 *
 * Example:
 *   const apiClient = new ApiClient(10000, window.location.origin);
 *   ...
 *   const user = await get('/user');
 *   ...
 *   await patch('/user', { 'email': user@example.com, 'name': 'Mike Rowe' }) // Automatically converted to JSON
 */
export class ApiClient {
  constructor(timeout, baseUrl) {
    this._timeout = timeout;
    this._baseUrl = baseUrl;

    this.requestFailed = new Signal();
  }

  addRequestFailedListener(callback) {
    this.requestFailed.add(callback);
  }

  removeRequestFailedListener(callback) {
    this.requestFailed.remove(callback);
  }

  async sendRequest(path, method, data = null) {
    const fullUrl = new URL(path, this._baseUrl);

    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json'
    };

    const isDataObject = typeof data === 'object' && data !== null;
    if (isDataObject)
      headers['Content-Type'] = 'application/json';

    const request = new Request(fullUrl.href, {
      method,
      headers,
      body: isDataObject ? JSON.stringify(data) : data,
      credentials: 'same-origin',
      timeout: this._timeout
    });

    const response = await fetch(request);

    let responseData = null;
    try {
      const responseTxt = await response.text();
      responseData = responseTxt.length > 0 ? JSON.parse(responseTxt) : null;
    } catch (e) {
      // Swallow exception
    }

    // If the response has a 4xx or 5xx
    if (!response.ok) {
      this.requestFailed.dispatch(response);

      throw new HttpException(
        !!responseData ? responseData.message : '',
        response.status
      );
    }

    return responseData;
  }

  /**
   * Sends a GET request to the given path.
   * 
   * @param {string} path 
   * @returns {any} Anything the API returns (and null if nothing is returned in the response's body)
   */
  get(path) {
    return this.sendRequest(path, 'GET');
  }

  /**
   * Sends a DELETE request to the given path.
   * 
   * @param {string} path 
   * @returns {any} Anything the API returns (and null if nothing is returned in the response's body)
   */
  delete(path) {
    return this.sendRequest(path, 'DELETE');
  }

  /**
   * Sends a POST request to the given path with the specified data.
   * 
   * @param {string} path
   * @param {any} data If the data is an object it will automatically be serialized to JSON
   * @returns {any} Anything the API returns (and null if nothing is returned in the response's body)
   */
  post(path, data = null) {
    return this.sendRequest(path, 'POST', data);
  }

  /**
   * Sends a PUT request to the given path with the specified data.
   * 
   * @param {string} path
   * @param {any} data If the data is an object it will automatically be serialized to JSON
   * @returns {any} Anything the API returns (and null if nothing is returned in the response's body)
   */
  put(path, data = null) {
    return this.sendRequest(path, 'PUT', data);
  }

  /**
   * Sends a PATCH request to the given path with the specified data.
   * 
   * @param {string} path
   * @param {any} data If the data is an object it will automatically be serialized to JSON
   * @returns {any} Anything the API returns (and null if nothing is returned in the response's body)
   */
  patch(path, data = null) {
    return this.sendRequest(path, 'PATCH', data);
  }
}

export let apiClient = null;
export const initializeApiClient = () => {
  const API_TIMEOUT = 10000;
  const API_BASE_URL = window.location.origin;

  apiClient = new ApiClient(API_TIMEOUT, API_BASE_URL);
};
