import { volumeToStr } from '../../utils/units';
import {
  DC_TYPE,
  FLUID_TYPE,
  PGN,
  TEMP_TYPE
} from './consts';

export const dcTypeToStr = (dcType, short = false) => {
  switch (dcType) {
    case DC_TYPE.BATTERY:
      return short ? 'Batt.' : 'Battery';

    case DC_TYPE.ALTERNATOR:
      return short ? 'Alter.' : 'Alternator';

    case DC_TYPE.CONVERTOR:
      return short ? 'Conv.' : 'Convertor';

    case DC_TYPE.SOLAR_CELL:
      return 'Solar cell';

    case DC_TYPE.WIND_GENERATOR:
      return short ? 'Wind gen.' : 'Wind generator';

    default:
      return '';
  }
};

export const fluidTypeToStr = (fluidType, short = false) => {
  switch (fluidType) {
    case FLUID_TYPE.FRESH_WATER:
      return short ? 'Fresh water l.' : 'Fresh water level';

    case FLUID_TYPE.FUEL:
      return short ? 'Fuel tank l.' : 'Fuel tank level';

    case FLUID_TYPE.GASOLINE:
      return short ? 'Gas. tank l.' : 'Gasoline tank level';

    case FLUID_TYPE.LIVE_WELL:
      return short ? 'Live well l.' : 'Live well level';

    case FLUID_TYPE.OIL:
      return short ? 'Oil tank l.' : 'Oil tank level';

    case FLUID_TYPE.WASTE_WATER:
      return short ? 'Waste water l.' : 'Waste water level';

    case FLUID_TYPE.BLACK_WATER:
      return short ? 'Black water l' : 'Black water level';

    default:
      return short ? 'Fluid l.' : 'Fluid level';
  }
};

export const temperatureToStr = (tempType, short = false) => {
  switch (tempType) {
    case TEMP_TYPE.WATER:
      return short ? 'Water temp.' : 'Water temperature';

    case TEMP_TYPE.INSIDE:
      return short ? 'Ins. temp.' : 'Inside temperature';

    case TEMP_TYPE.OUTSIDE:
      return short ? 'Out. temp.' : 'Outside temperature';

    case TEMP_TYPE.ENGINE_ROOM:
      return short ? 'Eng. room temp.' : 'Engine room temperature';

    case TEMP_TYPE.MAIN_CABIN:
      return short ? 'Main cabin temp.' : 'Main cabin temperature';

    case TEMP_TYPE.LIVE_WELL:
      return short ? 'Live well temp.' : 'Live well temperature';

    case TEMP_TYPE.BAIT_WELL:
      return short ? 'Bait well temp.' : 'Bait well temperature';

    case TEMP_TYPE.REFRIGERATION:
      return short ? 'Refr. temp.' : 'Refrigeration temperature';

    case TEMP_TYPE.HEATING:
      return short ? 'Heating temp.' : 'Heating temperature';

    case TEMP_TYPE.DEW_POINT:
      return 'Dew point';

    case TEMP_TYPE.WIND_CHILL_APPARENT:
      return short ? 'Apper. wind chill' : 'Apparent wind chill';

    case TEMP_TYPE.WIND_CHILL_THEORETIC:
      return short ? 'Theo. wind chill' : 'Theoretic wind chill';

    case TEMP_TYPE.HEAT_INDEX:
      return short ? 'Apper. temp.' : 'Apparent temperature';

    case TEMP_TYPE.FREEZER:
      return short ? 'Freezer temp.' : 'Freezer temperature';

    case TEMP_TYPE.EXHAUST_GAS:
      return short ? 'Exh. gas temp.' : 'Exhaust gas temperature';

    default:
      return short ? 'Temp.' : 'Temperature';
  }
};

/**
 * Gets a string from the given meta for the given pgn number.
 * POSSIBLE RETURNS:
 *   -LXM_IOT_TEMPERATURE: temperature source (examples: 'Water temp.', 'Refrigeration temp.', ...),
 *   -LXM_IOT_DC_STATUS_2: device type (examples: 'Battery', 'Convertor', ...),
 *   -LXM_IOT_FLUID: Capacity of the tank in user chosen units (example: 'Capacity: 20.0l')
 *   -null: No meta available
 */
export const getMetaLabel = (meta, pgn) => {
  if (parseInt(pgn) === PGN.LXM_IOT_FLUID) {
    let tankCapStr = null;
    if ('TankCapacity' in meta)
      tankCapStr = `Capacity: ${volumeToStr(meta.TankCapacity)}`;

    return tankCapStr;
  } else {
    return null;
  }
};