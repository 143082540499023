/*
  Collection of functions for API calls to the billing system.
*/

import { apiClient as client } from './base/api_client';

import { makeQueryString } from '../utils/web';
import { dateToUnix, unixToDate } from '../utils/general';

/*
  TRACKER_OBJ = {
    ID: number, // Tracker ID
    Imei: number, // IMEI
    RxCnt: number, // Number of bytes received
    TxCnt: number, // Number of bytes sent
    DataBalanceID: number, // ID of the trackers data balance
    RegistrationDate: Date, // Registration timestamp of tracker
    LastOnline: Date?, // Last online timestamp (NOTE: might be null)
    IsDisabled: bool, // Is tracker disabled
    SimId: number, // SIM card ID
    LastActivation: Date? // Date when tracker was last activated
    VesselID: number? // Id of the vessel this tracker belongs to (NOTE: might be null)
    UserID: number? // Id of the user that owns the vessel this tracker is assigned to (NOTE: will be null if VesselID is null) 
  }

  SIM_CARD_OBJ = {
    SimNumber: string,
    TelNumber: string,
    Pin: string,
    Puk: string,
    Puk2: string,
    Operater: string,
    Picture: string // Base64 encoded string of the picture
  }

  DATA_BALANCE_OBJ = {
    TxAmount: number, // Bytes transmitted
    RxAmount: number, // Bytes received
    ExpirationDate: Date, // Expiration date
    ID: number // Identifier
  }
*/

const mapTracker = t => {
  const {
    RegistrationDate,
    LastOnline,
    LastActivationDate,
    ...rest
  } = t;

  return {
    ...rest,
    RegistrationDate: unixToDate(RegistrationDate),
    LastOnline: LastOnline >= 0 ? unixToDate(LastOnline) : null,
    LastActivation: LastActivationDate >= 0 ? unixToDate(LastActivationDate) : null,
  };
};

/**
 * Generates a new data key on the server.
 * Returns a Promise with the data key (string).
 *
 * PARAMS:
 *   - txAmount: number // Number of bytes allowed to send
 *   - rxAmount: number // Number of bytes allowed to receive
 *   - keyValidUntil: number // Unix timestamp
 *   - dataExpiresIn: number // Number of seconds
 */
export function generateDataKey(txAmount, rxAmount, keyValidUntil, dataExpiresIn) {
  return client.put(
    'api/data-key',
    {
      TxAmount: txAmount,
      RxAmount: rxAmount,
      KeyValidUntil: keyValidUntil,
      DataExpiresIn: dataExpiresIn
    });
}

/**
 * Activates the currently logged-in user's data data balance with the supplied key.
 * Can optionally be suplied with a 'userId' param to activate the data-balance of the specified user (in this case the logged-in user must be an admin).
 *
 * Returns a Promise containing no result.
 */
export function activateDataBalance(dataKey, userId = null) {
  const data = {
    data_key: dataKey,
  };

  if (!!userId)
    data.user_id = userId;

  return client.put(
    'api/data-balance/activate-data-key',
    data
  );
}

/**
 * Returns the details of the logged-in user's data balance.
 * Can optionally be suplied with a 'userId' param to return that user's data balance (the logged-in user must be an admin for this to work).
 *
 * Returns a Promise containing the user's data balance (DATA_BALANCE_OBJ).
 */
export async function getDataBalance(userId = null) {
  let queryStr = '';
  if (!!userId)
    queryStr = makeQueryString({ user_id: userId });

  const dataBalance = await client.get(`api/data-balance${queryStr}`);

  const {
    ExpirationDate,
    ...rest
  } = dataBalance;

  return {
    ...rest,
    ExpirationDate: unixToDate(ExpirationDate)
  };
}

/**
 * Returns all trackers registered with the system. Only admins are allowed to call this endpoint.
 * Returns a Promise with the requested trackers (TRACKER_OBJ[]).
 */
export async function getTrackers() {
  const trackers = await client.get('api/trackers');

  return trackers.map(mapTracker);
}

/**
 * Returns the tracker identified by the 'imei' param. Only admins are allowed to call this endpoint.
 * Returns a Promise with the requested tracker (TRACKER_OBJ).
 */
export async function getTracker(imei) {
  const tracker = await client.get(`api/trackers/${imei}`);

  return mapTracker(tracker);
}

/**
 * Creates a new tracker on the system. Only admins are allowed to call this endpoint.
 * Returns a Promise with no result.
 */
export function createTracker(imei) {
  return client.put(
    'api/trackers',
    {
      IMEI: Number(imei)
    }
  );
}

/**
 * Disables or enables the given tracker. Only admins may call this endpoint.
 * Returns a Promise with no result.
 */
export function disableTracker(imei, disabled) {
  return client.patch(
    `api/trackers/${imei}/disabled`,
    disabled ? 'true' : 'false'
  );
}

/**
 * Deletes the given tracker. Only admins may call this endpoint. 
 * Returns a Promise with no result.
 */
export function deleteTracker(imei) {
  return client.delete(`api/trackers/${imei}`);
}

/**
 * Resets the activation date of the given tracker. Only admins may call this endpoint.
 * Returns a Promise with no result.
 */
export function resetTrackerActivation(imei) {
  return client.patch(`api/trackers/${imei}/reset-activation`);
}

/**
 * Returns information about a tracker's SIM card. Only admins may call this endpoint.
 * Returns a Promise with the tracker's SIM card info as its result (SIM_CARD_OBJ).
 */
export function getSimCard(imei) {
  return client.get(`api/trackers/${imei}/sim-card`);
}

/**
 * Registers a SIM card's information with the tracker identified by the 'imei' param. Only admins may call this endpoint.
 * Returns a Promise with no result.
 */
export function saveSimCardDetails(imei, simNum, telNum, pin, puk, puk2, operater, picture) {
  return client.put(
    `api/trackers/${imei}/sim-card`, {
      SimNumber: simNum,
      TelNumber: telNum,
      Pin: pin,
      Puk: puk,
      Puk2: puk2,
      Operater: operater,
      Picture: picture
    }
  );
}

/**
 * Retrieves a user's data usage for the given period.
 * The user is identified by the userId parameter, if it's null it identifies the currently logged in user.
 * NOTE: The logged in user must be an admin for this function to work with non-null user ID's
 */
export function getDataUsage(startTime, endTime, userId = null) {
  const start = dateToUnix(startTime);
  const end = dateToUnix(endTime);

  if (!!userId)
    return client.get(`api/users/${userId}/data-usage?start=${start}&end=${end}`);
  else
    return client.get(`api/account/data-usage?start=${start}&end=${end}`);
}

/**
 * Deletes the given tracker's related SIM card information. Only admins may call this endpoint.
 * Returns a Promise with no result.
 */
export function deleteSimCard(imei) {
  return client.delete(`api/trackers/${imei}/sim-card`);
}