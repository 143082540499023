/**
 *  Collection of functions for API calls that manipulate/read the user's account information.
 */

import { apiClient as client } from './base/api_client';

/**
 *  Creates an account for the user with the given data.
 * @param {string} username 
 * @param {string} email 
 * @param {string} password 
 * @returns {Promise<void>}
 */
 export function signUp(username, email, password) {
  return client.post(
    'api/account/sign-up', {
    username,
    email,
    password
  });
}

/**
 * Authenticates the user, and starts a cookie based session.
 * @param {string} email 
 * @param {string} password 
 * @param {boolean} rememberMe should the session persist infinitely 
 * @returns {Promise<void>}
 */
export function signIn(email, password, rememberMe = false) {
  return client.post(
    'api/account/sign-in', {
    email,
    password,
    remember_me: rememberMe
  });
}

/**
 * Logs-out the user.
 * @returns {Promise<void>}
 */
export function signOut() {
  return client.post('api/account/sign-out');
}

/**
 * Fetches the user's details from the server.
 * @returns {Promise<Object>} instace of USER_OBJ
 */
export function getUserDetails() {
  return client.get('api/account/details');
}

/**
 * Updates the current user's password.
 * @param {string} currentPassword 
 * @param {string} newPassword 
 * @returns {Promise<void>}
 */
export function changePassword(currentPassword, newPassword) {
  return client.put(
    'api/account/password', {
    password: currentPassword,
    newPassword
  });
}

/**
 * Enabled/disables public sharing for the current user.
 * @returns {Promise<Object>} the updated user's details (USER_OBJ)
 */
export function patchIsPublicSharingEnabled(isEnabled) {
  return patchAccountSettings({
    is_public_sharing_enabled: isEnabled
  });
}

/**
 * Updates warning notification settings for the current user.
 * @param {string} notificationEmail email to which notifications are sent
 * @param {boolean} notificationsEnabled are notifications enabled
 * @returns {Promise<Object>} the updated user's details (USER_OBJ)
 */
export function updateWarningNotificationSettings(notificationEmail, notificationsEnabled) {
  return patchAccountSettings({
    warning_notification_email: notificationEmail,
    warning_notifications_enabled: notificationsEnabled
  });
}

/**
 * Updates anchor watch settings for the current user.
 * @param {number} range maximum safe range of the anchor watch
 * @returns {Promise<Object>} the updated user's details (USER_OBJ)
 */
export function updateAnchorWatchSettings(range) {
  return patchAccountSettings({
    anchor_range: range
  });
}


/**
 * Updates the current user's account settings.
 * @param {Object} data object containg the fields/values to update
 * @returns {Promise<Object>} the updated user's details (USER_OBJ)
 */
export function patchAccountSettings(data) {
  return client.patch(
    'api/account/settings', {
    ...data
  });
}

/**
 * Used to modify the user's account details.
 * @param {string} email 
 * @param {string} username 
 * @param {string} password 
 * @returns @returns {Promise<Object>} the updated user's details (USER_OBJ)
 */
export function patchAccountInfo(email, username, password) {
  return client.patch(
    'api/account/details', {
    email,
    name: username,
    password
  });
}

/**
 * Sends a password reset link to the given mail if an account exists for it.
 * NOTE: For security reasons the server is expected to always return an OK response for this endpoint, even if the account does not exist.
 * @param {string} email the email to which to send the request 
 * @returns {Promise<void>}
 */
export function requestPasswordReset(email) {
  return client.post(
    'api/account/forgot-password', {
    email
  });
}

/**
 * Sets the user's new password using the password reset mechanism.
 * @param {string} resetToken obtained the password reset email
 * @param {string} email 
 * @param {string} newPassword 
 * @returns {Promise<void>}
 */
export function resetPassword(resetToken, email, newPassword) {
  return client.post(
    'api/account/reset-password', {
    token: resetToken,
    email,
    password: newPassword
  });
}