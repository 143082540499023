import { NODATA } from '../../utils/consts';
import { normalizeWhiteSpace } from '../../utils/general';
import { getPgnPropertyDefinition } from './definitions';
import { getMetaLabel } from './meta';

/**
 * Returns true if the passed value is not null, undefined or NODATA; false otherwise.
 */
 export const isValidPgnValue = num => (
  !isNaN(num)
  && isFinite(num)
  && num !== NODATA
);


/**
 * Creates a function that can be used to to validate a PGN data object.
 * If any of the corresponding keys values are null, undefined or NODATA the validator will return false; true otherwise.
 * 
 * Returns: object => boolean
 */
export const createHasDataValidator = keys => dataObj => {
  for (const k of keys) {
    if (!isValidPgnValue(dataObj[k]))
      return false;
  }

  return true;
};

/**
 * Finds the user friendly label for a pgn sub option. 
 */
export const getDescriptivePgnPropertyLabel = (pgn, propertyKey, meta = null, dataSrcLabel = null) => {
  const { getLabel } = getPgnPropertyDefinition(pgn, propertyKey) || {};
  if (!getLabel)
    return null;

  const label = getLabel(meta);

  // Trim the dataSrcLabel of any whitespaces
  dataSrcLabel = !!dataSrcLabel ? normalizeWhiteSpace(dataSrcLabel) : null;

  let metaStr = '';
  if (!!meta) {
    const metaLabel = getMetaLabel(meta, pgn);
    metaStr = metaLabel ? ` (${metaLabel})` : '';
  }

  return `${label}${dataSrcLabel ? ` (${dataSrcLabel})` : ''}${metaStr}`;
};
