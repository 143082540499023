/*
Component used to display global warnings/toast messages.

Public API exposed via props:
- NONE
*/

//Imported objects etc
import React, { Component } from 'react';
import { appEvents } from '../../events/app_events'

import {
  EuiGlobalToastList,
} from '@elastic/eui';

export default class extends Component {

//-------------------------------------------------------------------------------------------
//Component initialization stuff
//-------------------------------------------------------------------------------------------

  constructor(props) {
    super(props);

    this.state = {
      toasts: [],
    };

    this.nextToastId = 1;

    appEvents.display_toast.add(this.addToast);
    appEvents.clear_all_toasts.add(this.removeAllToasts);
  }

//-------------------------------------------------------------------------------------------
//Lifecycle methods
//-------------------------------------------------------------------------------------------

componentWillUnmount() {
  appEvents.display_toast.remove(this.addToast);
  appEvents.clear_all_toasts.remove(this.removeAllToasts);
}

//-------------------------------------------------------------------------------------------
//Subscribed event handlers
//-------------------------------------------------------------------------------------------

  addToast = (toast) => {
    const toastId = this.nextToastId++;
    this.setState(prevState => ({
      toasts: prevState.toasts.concat({ ...toast, id: `${toastId}` }),
    }));
  };

  removeAllToasts = () => {
    this.setState({
      toasts: [],
    });
  };

//-------------------------------------------------------------------------------------------
//Other
//-------------------------------------------------------------------------------------------

  removeToast = removedToast => {
    this.setState(prevState => ({
      toasts: prevState.toasts.filter(toast => toast.id !== removedToast.id),
    }));
  };

  render() {
    return (
      <EuiGlobalToastList
        toasts={this.state.toasts}
        dismissToast={this.removeToast}
        toastLifeTimeMs={6000}
      />
    );
  }
}