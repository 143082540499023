import { useEffect, useState } from 'react';
import { appEvents } from '../events/app_events';
import { displayPreferencesStorage } from '../store/store';

/** 
 * Custom hook that can be used to get and set the current theme. If the theme is set it will update the global theme.
 * Will update if the theme changes externally as well.
 */
export const useTheme = () => {
  const [localTheme, setLocalTheme] = useState(displayPreferencesStorage.getSelectedTheme());

  useEffect(() => {
    const onDisplayPrefsChanged = () => {
      const newThemeName = displayPreferencesStorage.getSelectedTheme();
      if (newThemeName !== localTheme)
        setLocalTheme(newThemeName);
    };

    appEvents.display_preferences_changed.add(onDisplayPrefsChanged);
    return () => appEvents.display_preferences_changed.remove(onDisplayPrefsChanged);
  }, [localTheme]);

  const changeThemeName = name => {
    displayPreferencesStorage.setSelectedTheme(name);
  };

  return [localTheme, changeThemeName];
};