import { isNil } from 'lodash';

import { USER_ROLE } from '../../../utils/consts';
import { unixToDate } from '../../../utils/general';
import UserSettings from './user_settings';

/**
 * Represents a user.
 */
class User {
  constructor() {
    this._id = null;
    this._email = null;
    this._username = null;
    this._dataBalanceId = null;
    this._createdAt = null;
    this._lastLoginAt = null;
    this._isDisabled = null;
    this._roles = [];
    this._settings = null;
  }

  get id() {
    return this._id;
  }

  set id(val) {
    this._id = val;
  }

  get username() {
    return this._username;
  }

  get dataBalanceId() {
    return this._dataBalanceId;
  }

  set dataBalanceId(val) {
    this._dataBalanceId = val;
  }

  set username(val) {
    this._username = val;
  }

  get email() {
    return this._email;
  }

  set email(val) {
    this._email = val;
  }

  get createdAt() {
    return this._createdAt;
  }

  set createdAt(val) {
    this._createdAt = val;
  }

  get lastLoginAt() {
    return this._lastLoginAt;
  }

  set lastLoginAt(val) {
    this._lastLoginAt = val;
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(val) {
    this._isDisabled = val;
  }

  get roles() {
    return this._roles;
  }

  set roles(val) {
    return this._roles = val;
  }

  /**
   * Returns true if the user is an admin and false otherwise.
   */
  get isAdmin() {
    return this._roles.includes(USER_ROLE.ADMIN);
  }

  get settings() {
    return this._settings;
  }

  set settings(val) {
    this._settings = val;
  }

  /**
   * Creates an equal copy of the user.
   * 
   * @returns {User}
   */
  clone() {
    const clone = new User();
    clone._id = this._id;
    clone._username = this._username;
    clone._email = this._email;
    clone._dataBalanceId = this._dataBalanceId;
    clone._isDisabled = this._isDisabled;
    clone._lastLoginAt = !!this._lastLoginAt ? new Date(this._lastLoginAt) : null;
    clone._createdAt = !!this._lastLoginAt ? new Date(this._createdAt) : null;
    clone._roles = !!this._roles ? [...this._roles] : null;
    clone._settings = !!this._settings ? this._settings.clone() : null;

    return clone;
  }

  _areDatesSame(other) {
    const {
      _lastLoginAt: lla,
      _createdAt: ca
    } = this;

    const {
      _lastLoginAt: othLla,
      _createdAt: othCa
    } = other;

    const llaSame = lla === othLla
    || (
      !!lla
      && !!othLla
      && lla.getTime() === othLla.getTime()
    );

    const caSame = ca === othCa
    || (
      !!ca
      && !!othCa
      && ca.getTime() === othCa.getTime()
    );
    
    return llaSame && caSame;
  }

  _areSettingsSame(other) {
    const {
      settings: s
    } = this;

    const {
      settings: othS
    } = other;

    return !!s && s.isEqual(othS);
  }

  _areRolesSame(other) {
    const {
      roles: r
    } = this;

    const {
      roles: othR
    } = other;

    return r === othR 
    || (
      !!r
      && !!othR
      && r.length === othR.length
      && this._roles.every(curr => othR.includes(curr))
    );
  }

  /**
   * Returns true if the passed paramater is an equal User istance and false otherwise.
   * 
   * @param {*} other 
   * @returns {boolean}
   */
  isEqual(other) {
    if (!(other instanceof User))
      return false;

    if (other === this)
      return true;

    return this._id === other._id
    && this._username === other._username
    && this._email === other._email
    && this._dataBalanceId === other._dataBalanceId
    && this._isDisabled === other._isDisabled
    && this._areDatesSame(other)
    && this._areSettingsSame(other)
    && this._areRolesSame(other);
  }

  /**
   * Creates an instance from a user object as returned by the API.
   * 
   * @param {Object} userObj 
   * @returns {User}
   */
  static createFromApiUser(userObj) {
    if (!userObj)
      return null;

    const instance = new User();

    instance._id = userObj.id;
    instance._username = userObj.name;
    instance._email = userObj.email;
    instance._dataBalanceId = userObj.data_balance_id;
    instance._isDisabled = userObj.is_disabled;
    instance._lastLoginAt = !isNil(userObj.last_login_at) ? unixToDate(userObj.last_login_at) : null;
    instance._createdAt = !isNil(userObj.created_at) ? unixToDate(userObj.created_at) : null;
    instance._roles = [...userObj.roles];
    instance._settings = !!userObj.settings ? UserSettings.createFromApiUserSettings(userObj.settings) : new UserSettings();

    return instance;
  }
}

export default User;