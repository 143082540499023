import { useEffect, useState } from 'react';
import { appEvents } from '../events/app_events';
import { getGlobalInstance } from '../service/live_data_service';

export const useGlobalData = () => {
  const instance = getGlobalInstance();
  const [data, setData] = useState(() => instance.getData());

  useEffect(() => {
    const cb = () => {
      const newData = instance.getData();
      setData(newData);
    };

    appEvents.global_data_loaded.add(cb);

    return () => {
      appEvents.global_data_loaded.remove(cb);
    };
  });

  return data;
};