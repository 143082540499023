import React from 'react';
import cx from 'classnames';

const NotificationBadge = ({
  children,
  className = null,
  ...rest
}) => (
  <span className={cx('lxnavNotificationBadge', className)} {...rest}>
    <span>
      {children}
    </span>
  </span>
);

export default NotificationBadge;