import { useState } from 'react';
import { privacyPreferencesStorage } from '../store/store';

/**
 * Hook to ease the use of global privacy preferences. Implemented in a useState style.
 * 
 * @returns [Object, Object => void]
 */
export const usePrivacyPreferences = () => {
  const [privacyPreferences, setPrivacyPreferences] = useState(privacyPreferencesStorage.get());

  const updateFunction = (newPrivacyPreferences) => {
    privacyPreferencesStorage.set(newPrivacyPreferences);
    setPrivacyPreferences(newPrivacyPreferences);
  };

  return [privacyPreferences, updateFunction];
};