import { constrain } from './general';

/**
 * @typedef RGBColor Represents a RGB color.
 * @property {number} r Red (0-255).
 * @property {number} g Green (0-255).
 * @property {number} b Blue (0-255).
 */

/**
 * @typedef ColorStep A numeric value with an associated color.
 * @property {number} value Numeric value of the step.
 * @property {RGBColor} color Color of the value .
 */

/**
 * @typedef ColorResultType
 * @property {string} rgbHex Hexadecimal representation of the given RGB color.
 * 
 * @typedef {RGBColor & ColorResultType} ColorResult 
 */

/**
 * Creates a {@link ColorResult} from the given params.
 * 
 * @param {number} r 
 * @param {number} g 
 * @param {number} b
 * 
 * @returns {ColorResult}
 */
function createResultColor(r, g, b) {
  const toHex = num => num.toString(16).padStart(2, '0');

  return {
    r,
    g,
    b,
    rgbHex: '#' + toHex(r) + toHex(g) + toHex(b)
  };
}

/**
 * Utility class used to the determine the color of a numeric value, using predefined pairs of numeric values and their corresponding colors. 
 */
class ColorDeterminator {
  /**
   * @param {ColorStep[]} steps Predefined color steps.
   */
  constructor(steps) {
    this.maxStep = steps.reduce(
      (max, step) => (step.value > max.value) ? step : max,
      steps[0]
    );

    this.minStep = steps.reduce(
      (min, step) => (step.value < min.value) ? step : min,
      steps[0]
    );

    this.steps = steps;
  }

  /**
   * Determines the appropriate color for the given value.
   * 
   * @param {number} value Any finite number 
   * @returns {ColorResult}
   */
  getColor(value) {
    // If there is only a single step defined simply return its color.
    if (this.steps.length < 2) {
      const { r, g, b } = this.steps[0].color;

      return createResultColor(r, g, b);
    }

    const normalizedValue = constrain(value, this.minStep.value, this.maxStep.value);

    // Determine into what section the target value belongs
    let upperStep = this.maxStep;
    let lowerStep = this.minStep;

    for (let i = 0; i < this.steps.length; i++) {
      const currStep = this.steps[i];

      const isBetterUpper = upperStep.value > currStep.value
        && lowerStep.value < currStep.value
        && normalizedValue < currStep.value;

      const isBetterLower = lowerStep.value < currStep.value
        && upperStep.value > currStep.value
        && normalizedValue >= currStep.value;

      if (isBetterUpper)
        upperStep = currStep;

      if (isBetterLower)
        lowerStep = currStep;
    }

    // Determine in what ratios the step colors should be mixed
    const {
      value: upperValue,
      color: upperColor
    } = upperStep;

    const {
      value: lowerValue,
      color: lowerColor
    } = lowerStep;

    const ratioLower = upperValue - normalizedValue;
    const ratioUpper = normalizedValue - lowerValue;

    /*
      Scale the ratios so that they add up to 1.
      The reason we need the scalars on a 0-1 range is because we want to keep the RGB ranges the same as they were (0-255).
      
      Reasoning:
      The upper and lower step value must be scaled by some scalar (x) so that they add up to 1.
      ratioLower * x + ratioUpper * x = 1

      (ratioLower + ratioUpper) * x = 1 // factor out x
      x = 1 / (ratioLower + ratioUpper) // divide both sides by (ratioLower + ratioUpper)
      x = 1 / ((upperValue - normalizedValue) + (normalizedValue - lowerValue)) // Expand (ratioLower + ratioUpper)
      x = 1 / (upperValue - lowerValue) // Simplify
    */
    const scalar = 1 / (upperValue - lowerValue);
    const lowerScalar = ratioLower * scalar;
    const upperScalar = ratioUpper * scalar;

    // Mix corresponding components of both colors in the calculated ratio
    const {
      r: upR,
      g: upG,
      b: upB
    } = upperColor;

    const {
      r: loR,
      g: loG,
      b: loB
    } = lowerColor;

    const mix = (lo, up) => Math.floor((lo * lowerScalar + up * upperScalar));
    const r = mix(loR, upR);
    const g = mix(loG, upG);
    const b = mix(loB, upB);

    return createResultColor(r, g, b);
  }
}

export default ColorDeterminator;