import { withAuth } from './with_auth';
import { withAuthManagement } from './with_auth_management';
import { withAuthGuard } from './with_auth_guard';
import { getAuthManager, getAuthInvalidatedListener } from '../../core/authentication/global';
import { AUTH_LEVEL } from '../../utils/consts';

/**
 * HOCs that ease that allow access to the global authentication instances.
 */

export const withGlobalAuth = Component => {
  const authManager = getAuthManager();
  const invalidationListener = getAuthInvalidatedListener();

  return withAuth(authManager, invalidationListener)(Component);
};

export const withGlobalAuthManagement = Component => {
  const authManager = getAuthManager();

  return withAuthManagement(authManager)(Component);
};

export const withGlobalSignInGuard = Component => (
  withGlobalAuth(
    withAuthGuard(Component, AUTH_LEVEL.SIGNED_IN, window.location.pathname)
  )
);

export const withGlobalAdminGuard = Component => (
  withGlobalAuth(
    withAuthGuard(Component, AUTH_LEVEL.ADMINISTRATOR, user => !!user ? '/home' : window.location.pathname)
  )
);