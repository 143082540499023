// No data constant for PGN values;
export const NODATA = 2147483647;

export const UNIT_SPACE = " ";
export const UNIT_SLASH = "/";
export const UNIT_KM = "km";
export const UNIT_NM = "nm";
export const UNIT_MI = "mi";
export const UNIT_M = "m";
export const UNIT_FT = "ft";
export const UNIT_IN = "in";
export const UNIT_CM = "cm";
export const UNIT_MM = "mm";

export const UNIT_M2 = "m2";
export const UNIT_FT2 = "ft2";

export const UNIT_MS = "m/s";
export const UNIT_KMH = "km/h";
export const UNIT_KTS = "kts";
export const UNIT_FPM = "f/m";
export const UNIT_MPH = "m/h";

export const UNIT_KG = "kg";
export const UNIT_LBS = "lbs";

export const UNIT_KGM2 = "kg/m2";
export const UNIT_LBFT2 = "lb/ft2";

export const UNIT_CEL = "°C";
export const UNIT_FAR = "°F";
export const UNIT_KEL = "°K";

export const UNIT_MBAR = "mbar";
export const UNIT_MMHG = "mmhg";
export const UNIT_INHG = "inhg";
export const UNIT_HPA = "hPa";
export const UNIT_PA = "Pa";
export const UNIT_BAR = "bar";
export const UNIT_PSI = "psi";
export const UNIT_KPA = "kPa";

export const UNIT_DEG = "°";
export const UNIT_DEGT = "degt";
export const UNIT_DEGM = "degm";

export const UNIT_LIT = "l";
export const UNIT_GAL = "g";
export const UNIT_GAL_IMP = "gi";

export const UNIT_LPH = "l/h";
export const UNIT_GPH = "g/h";
export const UNIT_GPHI = "gi/h";

export const UNIT_LP100KM = "l/100km";
export const UNIT_MPG = "mi/g";
export const UNIT_MPIG = "mi/ig";
export const UNIT_NMPG = "nm/g";
export const UNIT_NMPIG = "nm/ig";
export const UNIT_KMPL = "km/l";

export const UNIT_PERC = "%";

export const UNIT_BYTE = "B";
export const UNIT_KIBIBYTE = "KiB";
export const UNIT_MEBIBYTE = "MiB";
export const UNIT_KILOBYTE = "KB";
export const UNIT_MEGABYTE = "MB";

// Distance conversions
export const MM2M = 0.001;
export const CM2M = 0.01;
export const KM2M = 1000;
export const INCH2M = 0.0254;
export const FT2M = 0.3048;
export const MI2M = 1609.343;
export const NM2M = 1853.181;

export const M2MM = 1000;
export const M2CM = 100;
export const M2KM = 0.001;
export const M2INCH = 39.37007874;
export const M2FT = 3.2808399;
export const M2NM = 0.0005396127;
export const M2MI = 0.0006213712;

// Speed conversions
export const CMS2MS = 0.01;
export const KPH2MS = 0.2777777777;
export const KTS2MS = 0.5147724242;
export const KTS2KMH = 1.853181;
export const KMH2KTS = 0.5396127;
export const KMH2MPH = 0.6213712;
export const MPH2MS = 0.4470397222;
export const FPM2MS = 0.0050799999;
export const MS2CMS = 100.0;
export const MS2KPH = 3.6;
export const MS2KTS = 1.942606;
export const MS2MPH = 2.2369376820;
export const MS2FPM = 196.85039757579523;
export const MS2PLR = 0.036;
export const PLR2MS = 27.77777777;

// Pressure conversions
export const M2PA = 11.76470588;
export const MBAR2PA = 100;
export const HPA2PA = 100;
export const MMHG2PA = 133.32239;
export const INHG2PA = 3386.38866666;
export const MMH2O2PA = 9.80665;
export const PA2M = 0.085;
export const PA2MBAR = 0.01;
export const PA2HPA = 0.01;
export const PA2MMHG = 0.0075006156;
export const PA2INHG = 0.0002952998;
export const PA2MMH2O = 0.10197162129779283;
export const PA2PSI = 0.000145038;
export const PSI2PA = 6894.76;
export const PA2BAR = 0.00001;
export const BAR2PA = 100000;
export const KPA2PA = 1000;
export const PA2KPA = 0.001;

// Load conversions
export const LBPFT22KGPM2 = 4.8824276;
export const KGPM22LBPFT2 = 0.20481614514;

// Weight conversions
export const KG2LBS = 2.20462262;
export const LBS2KG = 0.45359237;

// Temperature conversion
export function CEL2FAR(a) { return (1.8 * (a) + 32.0) }
export function FAR2CEL(a) { return ((5. / 9.) * ((a) - 32.0)) }
export function KEL2CEL(a) { return ((a) - 273.15) }
export function CEL2KEL(a) { return ((a) + 273.15) }

// Volume conversion
export const IMP_GAL2L = 4.54609;
export const L2IMP_GAL = 0.219969;

export const GAL2L = 3.785411784;
export const L2GAL = 0.2641720523;
export const FUELVOLUME2WEIGHT = 0.737;
export const FUELWEIGHT2VOLUME = 1.0 / FUELVOLUME2WEIGHT;

export function LP100KM2MPG(VAL) { return (253. / VAL) }
export function LP100KM2IMPG(VAL) { return (282. / VAL) }

// Flow conversions
export const LPH2GPH = 0.264172;
export const GPH2LPH = 3.785412;

export const LPH2GPH_IMP = 0.219969;
export const GPH2LPH_IMP = 4.546090;

export const P_STD = 101325;
export const GRAVITY = 9.81;

export const DAY2MIN = 1440;
export const DAY2SEC = 86400;
export const DAY2MSEC = 86400000;
export const SEC2MSEC = 1000;

export const MIN2DAY = (1. / 1440.);
export const SEC2DAY = (1. / 86400.);
export const MSEC2DAY = (1. / 86400000.);
export const MSEC2SEC = (1 / 1000);

export const PI = 3.1415926535897932384626433832795;
export const INV_PI = 0.31830988618376972221407084627967;

export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;

export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;

export const LATDEGM = 111191;
export const LATRADM = LATDEGM * LONGITUDE_MAX / PI;
export const DEG2RAD = PI / LONGITUDE_MAX;
export const RAD2DEG = LONGITUDE_MAX / PI;

export const LATRAD2M = (6370775.0198391363406120636319352); //LATDEG2M*180/PI
export const M2LATRAD = (1.5696677356929334001166378290407e-7);

export const KIB_SIZE = 1024;
export const MIB_SIZE = KIB_SIZE * 1024;

export const KB_SIZE = 1000;
export const UNIT_SIZE = KB_SIZE * 10;
export const MB_SIZE = KB_SIZE * 1000;

export const BYTES2KIB = 1 / KIB_SIZE;
export const BYTES2MIB = 1 / MIB_SIZE;

export const BYTES2KB = 1 / KB_SIZE;
export const BYTES2MB = 1 / MB_SIZE;

// Warnings
export const WARNING__COUNT_LIMIT = 99;

// Vessel configuration options
export const VESSEL_CONFIG__VERSION = 1;
export const VESSEL_CONFIG__KEY__VERSION = 'version';
export const VESSEL_CONFIG__KEY__CHART = 'chart';
export const VESSEL_CONFIG__KEY__PATH_SRC = 'pathDataSrcName';

// Data balance related constants
export const DATA_BALANCE__KEY__ID = 'ID';
export const DATA_BALANCE__KEY__RX_AMOUNT = 'RxAmount';
export const DATA_BALANCE__KEY__TX_AMOUNT = 'TxAmount';
export const DATA_BALANCE__KEY__EXPIRATION = 'ExpirationDate';

export const DATA_BALANCE__WARN_LIMIT__LOW_DATA = 1 * MB_SIZE; // In bytes
export const DATA_BALANCE__WARN_LIMIT__EXPIRATION = 1000 * 3600 * 24 * 14; // In ms

// Anchor
export const ANCHOR_WATCH__MIN_RANGE = 0; // Minimum valid range for the anchor mode (in meters)
export const ANCHOR_WATCH__MAX_ENABLE_SPEED = 2.5; // Maximum allowed speed at which the user can still enable anchoring (in meters per second)

// Password reset
export const PASSWORD_RESET__KEY__TOKEN = 'resetToken';
export const PASSWORD_RESET__KEY__EMAIL = 'email';

export const GEOFENCE__RESTRICTION_TYPES = {
  RESTRICTED_INSIDE: 'RestrictedInside',
  RESTRICTED_OUTSIDE: 'RestrictedOutside'
};

export const GEOFENCE__FEATURE_TYPES = {
  POLYGON: 'polygon',
  POLYGON_VERTEX: 'polygon_vertex',
  POLYGON_VIRTUAL_VERTEX: 'polygon_virtual_vertex',
};

export const KEYBOARD__KEY_CODES = {
  ESCAPE: 'Escape',
  DELETE: 'Delete'
};

export const ANCHOR__FEATURE_TYPES = {
  ANCHOR: 'anchor'
};

export const GEOJSON__FEATURE_TYPES = {
  FEATURE_COLLECTION: 'FeatureCollection',
  FEATURE: 'Feature',
};

export const GEOFENCE__AUTOGENERATED_ID_PREFIX = '__webc_autogen_geofence_id__';

export const GEOJSON__GEOMETRY_TYPES = {
  POINT: 'Point',
  MULTI_POINT: 'MultiPoint',
  LINE_STRING: 'LineString',
  MULTI_LINE_STRING: 'MultiLineString',
  POLYGON: 'Polygon',
  MULTI_POLYGON: 'MultiPolygon'
};

// Viewport
export const VIEWPORT__SHORT_HEIGHT_LIMIT = 800; // In pixels

// Various reusable string constants
export const STR__UNKNOWN = 'Unknown';

// Time frame in which the device has to communicate with the server, otherwise its active session becomes closed (given in seconds)
export const SESSION__CLOSE_TIMEOUT = 60 * 10;

export const VESSEL_STATUS = Object.freeze({
  LOADING: 'loading',
  ONLINE: 'online',
  OFFLINE: 'offline',
  UNKNOWN: 'unknown',
  UNASSIGNED: 'not_assigned'
});

export const SESSION_EVENT_TYPE = Object.freeze({
  START: 'start',
  END: 'end',
  STARTEND: 'startend',
  MIDWAY: 'midway'
});

export const MARKER_TYPE = Object.freeze({
  VESSEL_ONLINE: 'vessel_online',
  VESSEL_OFFLINE: 'vessel_offline',
  SMALL_VESSEL_ONLINE: 'small_vessel_online',
  SMALL_VESSEL_OFFLINE: 'small_vessel_offline',
  DOT: 'dot',
  SMALL_DOT: 'small_dot',
  NORMAL: 'normal'
});

export const CHART_TYPE = Object.freeze({
  NONE: 'none',
  LINE: 'line',
  AREA: 'area',
  BAND_BAR: 'band_bar'
});

// NOTE: these values are defined by the server!
export const USER_ROLE = Object.freeze({
  CLIENT: 'client',
  ADMIN: 'admin'
});

export const MAP_MODE = Object.freeze({
  FREE: 'free',
  NORTH_UP: 'north_up',
  TRACK_UP: 'track_up'
});

export const APPLICATION_THEME = Object.freeze({
  LXNAV_LIGHT: 'lxnav_light',
  LXNAV_DARK: 'lxnav_dark'
});

export const COMPONENT_THEME = Object.freeze({
  LIGHT: 'light',
  DARK: 'dark',
  AUTO: 'auto'
});

export const MESSAGE_BOX_STATE = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
});

export const SORTING_DIRECTION = Object.freeze({
  ASC: 'asc',
  DESC: 'desc'
});

// NOTE: This is only of list of known manufacturers (others that are not in this list may and will appear)!
// The values are defined by NMEA2000 devices themselves.
export const MANUFACTURERS = {
  LXNAV: 'LXNAV'
};

// NOTE: This is only of list of known manufacturers (others that are not in this list may and will appear)!
// The values are defined by NMEA2000 devices themselves.
export const PRODUCT_TYPES = {
  SMARTGPS: 'SmartGPS'
};

export const DEVICE_PERIOD_OPTIONS = Object.freeze([
  { text: '1 s', value: 1 * SEC2MSEC },
  { text: '2 s', value: 2 * SEC2MSEC },
  { text: '3 s', value: 3 * SEC2MSEC },
  { text: '4 s', value: 4 * SEC2MSEC },
  { text: '5 s', value: 5 * SEC2MSEC },
  { text: '10 s', value: 10 * SEC2MSEC },
  { text: '30 s', value: 30 * SEC2MSEC },
  { text: '1 min', value: 60 * SEC2MSEC },
  { text: '5 min', value: 300 * SEC2MSEC },
  { text: '10 min', value: 600 * SEC2MSEC },
  { text: '30 min', value: 1800 * SEC2MSEC },
  { text: '1 h', value: 3600 * SEC2MSEC }
]);

export const WARNING_LEVEL = Object.freeze({
  ATTENTION: 1,
  DANGER: 0
});

/**
 * Warning categories. Warnings of different categories cary different information and behave differently.
 * Every warning BELONGS TO EXACTLY ONE category!
 */
export const WARNING_CATEGORY = Object.freeze({

  /**
   * Basic warning (can only be disabled/enabled)
   */
  BASIC: 'basic',

  /**
   * Warnings that accept 2 level params, the warning is raised once the value rises/falls above/below the specified levels)
   */
  TWO_LEVEL_PARAMETERIZED: 'two_level_parameterized',

  /**
   * Warnings related to vessel geofences
   */
  GEOFENCE: 'geofence',

  /**
   * Warnings related to vessel anchors
   */
  ANCHOR: 'anchor',

  /**
   * Category of unknown warnings.
   */
  UNDEFINED: 'undefined'
});

/**
 * Authentication levels as defined by the app.
 */
export const AUTH_LEVEL = {
  /**
   * Asserts that the user must be signed-in
   */
  SIGNED_IN : 'signed_in',

  /**
   * Asserts that the user must be an administrator
   */
  ADMINISTRATOR: 'admin'
};

export const WARNING_TYPE = Object.freeze({
  LXM_IOT_WARN_PARAM_RPM_SPEED: 0,
  LXM_IOT_WARN_PARAM_FUEL_RATE: 1,
  LXM_IOT_WARN_PARAM_ENGINE_TEMP: 2,
  LXM_IOT_WARN_BASIC_CHECK_ENGINE: 3,
  LXM_IOT_WARN_BASIC_OVER_TEMPERATURE: 4,
  LXM_IOT_WARN_BASIC_LOW_OIL_PRESSURE: 5,
  LXM_IOT_WARN_BASIC_LOW_OIL_LEVEL: 6,
  LXM_IOT_WARN_BASIC_LOW_FUEL_PRESSURE: 7,
  LXM_IOT_WARN_BASIC_LOW_SYSTEM_VOLTAGE: 8,
  LXM_IOT_WARN_BASIC_LOW_COOLANT_LEVEL: 9,
  LXM_IOT_WARN_BASIC_WATER_FLOW: 10,
  LXM_IOT_WARN_BASIC_WATER_IN_FUEL: 11,
  LXM_IOT_WARN_BASIC_CHARGE_INDICATOR: 12,
  LXM_IOT_WARN_BASIC_PREHEAT_INDICATOR: 13,
  LXM_IOT_WARN_BASIC_HIGH_BOOST_PRESSURE: 14,
  LXM_IOT_WARN_BASIC_REV_LIMIT_EXCEEDED: 15,
  LXM_IOT_WARN_BASIC_EGR_SYSTEM: 16,
  LXM_IOT_WARN_BASIC_THROTTLE_POSITION_SENSOR: 17,
  LXM_IOT_WARN_BASIC_ENGINE_EMERGENCY_STOP: 18,
  LXM_IOT_WARN_BASIC_WARNING_LEVEL_1: 19,
  LXM_IOT_WARN_BASIC_WARNING_LEVEL_2: 20,
  LXM_IOT_WARN_BASIC_POWER_REDUCTION: 21,
  LXM_IOT_WARN_BASIC_MAINTENANCE_NEEDED: 22,
  LXM_IOT_WARN_BASIC_ENGINE_COMM_ERROR: 23,
  LXM_IOT_WARN_BASIC_SUB_OR_SECONDARY_THROTTLE: 24,
  LXM_IOT_WARN_BASIC_NEUTRAL_START_PROTECT: 25,
  LXM_IOT_WARN_BASIC_ENGINE_SHUTTING_DOWN: 26,
  LXM_IOT_WARN_PARAM_ENGINE_TRIM: 27,
  LXM_IOT_WARN_PARAM_TEMP: 28,
  LXM_IOT_WARN_PARAM_SOC: 29,
  LXM_IOT_WARN_PARAM_VOLTAGE: 30,
  LXM_IOT_WARN_PARAM_CURRENT: 31,
  LXM_IOT_WARN_PARAM_GNSS_SPEED: 32,
  LXM_IOT_WARN_PARAM_WREF_SPEED: 33,
  LXM_IOT_WARN_PARAM_FLUID: 34,
  LXM_IOT_WARN_PARAM_DEPTH: 35,
  LXM_IOT_WARN_PARAM_BATTERY_TEMP: 36,
  LXM_IOT_WARN_SPECIAL_GEOFENCE: 37,
  LXM_IOT_WARN_SPECIAL_ANCHOR: 38
});

export const NAV_PAGES = {
  HOME: {
    label: 'Home',
    baseUrls: ['/', '/home']
  },
  VESSELS: {
    label: 'Vessels',
    baseUrls: ['/vessels']
  },
  SESSIONS: {
    label: 'Sessions',
    baseUrls: ['/sessions']
  },
  SETTINGS: {
    label: 'Settings',
    baseUrls: ['/settings']
  },
  FAVORITES: {
    label: 'Favorites',
    baseUrls: ['/favorites']
  },
  WARNINGS: {
    label: 'Warnings',
    baseUrls: ['/warnings']
  }
};