/*
  Component used to render a caret. The rotation is animated.

  Public API exposed via props:
  - closedRotation: number, // Rotation of the caret when the button is in a closed state
  - openRotation: number, // Rotation of the caret when the button is in a open state 
  - isOpen: boolean, // Is the button in a closed or open state
  - rotationSpeed (optional): 'xfast'|'fast'|'normal'|'slow'|'xslow', // Animation speed; default is 'normal' 
*/

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { EuiImage } from '@elastic/eui';

import caretSvg from '../../../assets/icons/general/caret.svg';

const Caret = ({
  closedRotation,
  openRotation,
  isOpen,
  rotationSpeed = 'normal'
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const rotation = isOpen ? openRotation : closedRotation;
  const style = {
    transform: `rotate(${rotation}deg)`,
  };

  const caretClassName = cx(
    'lxnavCaret',
    {
      [`lxnavCaret--animated--${rotationSpeed}`]: isMounted // Do not animate initial transition
    }
  );

  return (
    <EuiImage
      url={caretSvg}
      className={caretClassName}
      style={style}
      alt=''
    />
  );
};

export default Caret;