/*
  Component used to simulate a dropdown menu.
  
  Public API exposed via props:
  -isOpen: boolean, // Is the dropdown open
  -children: ReactNode // Dropdown contents
  -flushTop (optional): boolean // Should the top of the dropdown fit flushly to the bottom of its anchor; defaults to false 
*/

import React, { useRef, useLayoutEffect, useState } from 'react';
import cx from 'classnames';

const Dropdown = ({
  isOpen,
  children,
  flushTop = false,
  className = null
}) => {
  const containerRef = useRef();
  const contentRef = useRef();
  const [isInitial, setIsInitial] = useState(true);

  useLayoutEffect(() => {
    const performLayoutChanges = () => {
      const { current } = containerRef;
  
      current.style.display = isOpen ? 'initial' : 'none';
      const computedStyle = getComputedStyle(current.parentNode);
      if (computedStyle.position === 'static' || computedStyle.position === 'relative')
        current.style.position = 'absolute';
      else
        current.style.position = 'static';
    };

    const { current } = contentRef;

    if (!isOpen) {
      current.style.transform = 'translateY(-110%)';
      if (!isInitial) {
        // Once translate is complete run the layout changes 
        const onTransitionEnd = () => {
          current.removeEventListener('transitionend', onTransitionEnd);
          performLayoutChanges();
        };
        current.addEventListener('transitionend', onTransitionEnd);

        return () => current.removeEventListener('transitionend', onTransitionEnd);
      } else {
        setIsInitial(false);
        performLayoutChanges();
      }
    } else {
      // Perform the layout changes and after they're done run the translation
      performLayoutChanges();
      setTimeout(() => {
        current.style.transform = 'translateY(0)';
      }, 0)
    }
  }, [isOpen, contentRef, containerRef, isInitial]);

  const finalClassName = cx (
    'lxnavDropdown', {
      'lxnavDropdown--flushTop': flushTop,
      className
    }
  );

  return (
    <div className={finalClassName} ref={containerRef}>
      <div className='lxnavDropdown__content' ref={contentRef}>
        <div className='lxnavDropdown__content__inner'> 
          {children}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;