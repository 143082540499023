import {
  getLiveData,
  getLiveDataBatched
} from '../../api/vessels_api';
import { PGN } from '../../core/pgn/consts';
import { isValidPgnValue } from '../../core/pgn/utils';
import {
  NODATA,
  VESSEL_STATUS
} from '../consts';
import { isValidLat } from '../geo_json';
import { calculateFuelEconomy } from '../units';
import {
  VESSEL_STAT__TOTAL_DIST,
  VESSEL_STAT__TOTAL_FUEL,
  VESSEL_STAT__TOTAL_ONLINE,
  VESSEL_STAT__TRIPS_CNT
} from './vessel_statistics';

/**
 * @callback reportValidator
 * @param {LiveDataReport} report
 * 
 * @returns {boolean}
 */

/**
 * @callback dataValidator
 * @param {{
 *   T: number,
 *   [string]: number
 * }}
 */

export default class LiveData {
  constructor(vesselId) {
    this.vesselId = vesselId;
    this.reports = [];

    this.addReport = this.addReport.bind(this);
    this.loadReport = this.loadReport.bind(this);

    this.getLatestReport = this.getLatestReport.bind(this);
    this.getTraveledPath = this.getTraveledPath.bind(this);
  }

  /**
   * Fetches a new active session report from the server and stores it.
   * 
   * @returns {Promise<LiveDataReport>} Loaded report
   */
  async loadReport() {
    const instance = await LiveDataReport.load(this.vesselId);
    this.addReport(instance);

    return instance;
  }

  /**
   * Adds a report to the instance.
   *
   * @param {LiveDataReport} report
   */
  addReport(report) {
    this.reports.push(report);
  }

  /**
   * Returns the most recently loaded report, that passed the provided validator function.
   * @param {reportValidator} validator
   * 
   * @returns {LiveDataReport} matched report
   */
  getLatestReport(validator = null) {
    const {
      reports
    } = this;

    for (let i = reports.length - 1; i >= 0; i--) {
      const curr = reports[i];

      if (!validator || validator(curr))
        return curr;
    }

    return null;
  }

  /**
   * Returns an array of positional objects, corresponding to the path the vessel has traveled.
   *
   * @returns {{
   *  data: {
   *    Lat: number,
   *    Lon: number,
   *    T: number
   *  },
   *  name: string
   * }[]}
   */
  getTraveledPath(dataSrcName = null) {
    const { reports } = this;

    const posArr = [];
    for (let i = 0; i < reports.length; i++) {
      const curr = reports[i];

      const pos = curr.getPosition(dataSrcName);
      if (!!pos)
        posArr.push(pos);
    }

    return posArr;
  }
}

// Utility class used to abstract the use of active sessions
export class LiveDataReport {
  constructor(activeSessionObj) {
    this._activeSessionObj = activeSessionObj;

    this.getPgn = this.getPgn.bind(this);
    this.getCourse = this.getCourse.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.isOnline = this.isOnline.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getAvailableDataSourceNames = this.getAvailableDataSourceNames.bind(this);
    this.getLastKnownPosition = this.getLastKnownPosition.bind(this);
    this.getDeviceData = this.getDeviceData.bind(this);
    this.getWarnings = this.getWarnings.bind(this);
    this.getDeviceWarnings = this.getDeviceWarnings.bind(this);
    this.getDevicesData = this.getDevicesData.bind(this);
    this.getVesselStatistics = this.getVesselStatistics.bind(this);
  }

  /**
   * Creates an instance with the data returned by the API for the given vessel.
   *
   * @param {number|string} vesselId 
   * @returns {LiveDataReport} New instance
   */
  static async load(vesselId) {
    const asObj = await getLiveData(vesselId);

    return new LiveDataReport(asObj);
  }

  /**
   * Creates an object whose keys are the given vesselIds, the values are corresponding LiveDataReport instances.
   *
   * @param {Array<number|string>} vesselIds 
   * @returns {{[VesselID]: LiveDataReport}}
   */
  static async loadBatched(vesselIds) {
    const responseArray = await getLiveDataBatched(vesselIds);

    const resObj = {};
    for (const { VesselID, LiveData } of responseArray)
      resObj[VesselID] = new LiveDataReport(LiveData);

    return resObj;
  }

  /**
   * Returns the given PGN object, a data source can optionally be specified (if not given the first matching PGN found will be returned).
   * Can also be supplied with a validator function, it will be passed the data object, if the validator then returns false the data is disregarded.
   *
   * @param {number} pgnNum
   * @param {string?} dataSrcName
   * @param {dataValidator?} validator
   * @returns {{
   *  data: {
   *     T: number,
   *     [string]: number
   *   },
   *   name: string
   * }}
   */
  getPgn(pgnNum, dataSrcName = null, validator = null) {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const pgnObj = asObj.SessionData[pgnNum];
    if (!pgnObj)
      return null;

    const dataSources = pgnObj.DataSources || [];
    for (let i = 0; i < dataSources.length; i++) {
      const { Data, Name } = dataSources[i];

      if (!!dataSrcName && Name !== dataSrcName)
        continue;

      const passedValidator = !validator || validator(Data);
      if (!passedValidator) {
        if (!!dataSrcName) // Check if a specific data source was being requested
          break;
        else
          continue;
      }

      return {
        data: Data,
        name: Name
      };
    }

    return null;
  }

  /**
   * Gets the course of the vessel, a data source name can optionally be specified (if not given the first one found will be returned).
   *
   * @param {string?} dataSrcName 
   * @returns {number} course
   */
  getCourse(dataSrcName = null) {
    const dataObj = this.getPgn(PGN.LXM_IOT_GNSS_DATA_2, dataSrcName);
    if (!dataObj)
      return null;

    return dataObj.data.COG;
  }

  /**
   * Get the last known position of the vessel. Returns null if not available.
   *
   * @returns {{ Lat: number, Lon: number, COG: number|null, T: number } | null}
   */
  getLastKnownPosition() {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const pos = asObj.Pos;
    if (!pos)
      return null;

    const {
      T,
      Lat,
      Lon,
      Cog
    } = pos;

    if (!isValidPgnValue(Lat) || !isValidPgnValue(Lon) || !isValidPgnValue(T))
      return null;

    return {
      Lat,
      Lon,
      COG: isValidPgnValue(Cog) ? Cog : null,
      T
    };
  }

  /**
   * Gets the vessel's position, a data source name can optionally be specified (if not given the first one found will be returned).
   * 
   * @param {string?} dataSrcName
   * @returns {{ Lat: number, Lon: number, T: number }}
   */
  getPosition(dataSrcName = null) {
    const validator = ({ Lat, Lon }) => isValidLat(Lat) && isValidLat(Lon);
    return this.getPgn(PGN.LXM_IOT_GNSS_DATA_1, dataSrcName, validator);
  }

  /**
   * Tells whether the vessel is online.
   *
   * @returns {boolean}
   */
  isOnline() {
    return this._activeSessionObj.Online;
  }

  /**
   * Returns whether the vessel is online.
   *
   * @returns {string}
   */
  getStatus() {
    return this.isOnline() ? VESSEL_STATUS.ONLINE : VESSEL_STATUS.OFFLINE;
  }

  /**
   * Returns the names of the data sources available for the given PGN.
   *
   * @param {string|number} pgnNum 
   * @returns {string[]} available data source names
   */
  getAvailableDataSourceNames(pgnNum) {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const pgnObj = asObj.SessionData[pgnNum];
    if (!pgnObj)
      return [];

    const dsArray = [];
    const dataSources = pgnObj.DataSources || [];
    for (let i = 0; i < dataSources.length; i++) {
      const { Data, Name } = dataSources[i];

      if (!Data)
        continue;

      dsArray.push(Name);
    }

    return dsArray;
  }

  /**
   * Retrieves a single device's data.
   * 
   * @param {string} dataSrcName 
   * @returns {{
   *   [pgn: number]: {
   *     T: number
   *     [property: string]: number
   * }}
   */
  getDeviceData(dataSrcName) {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const { SessionData } = asObj;
    const resObj = {};
    for (const pgnNum in SessionData) {
      for (const { Data, Name } of SessionData[pgnNum].DataSources) {
        if (Name === dataSrcName) {
          resObj[pgnNum] = { ...Data };
          break;
        }
      }
    }

    return resObj;
  }

  /**
   * Returns the data of all devices in normalized form.
   *
   * @returns {{
   *   [dataSrcName: string]: {
   *     [pgn: number]: {
   *     T: number,
   *     [property: string]: number
   * }}
   */
  getDevicesData() {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const { SessionData } = asObj;

    const resObj = {};
    for (const pgnNum in SessionData) {
      for (const { Data, Name } of SessionData[pgnNum].DataSources) {
        resObj[Name] = {
          ...(resObj[Name]),
          [pgnNum]: Data
        };
      }
    }

    return resObj;
  }

  /**
   * Retrieves the vessel's statistics.
   * 
   * @returns {{
   *   TripCnt: number,
   *   TotDist: number,
   *   AvgDist: number,
   *   TotFuel: number,
   *   AvgFuelEco: number,
   *   TOnl: number
   * }}
   */
  getVesselStatistics() {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    const {
      [VESSEL_STAT__TRIPS_CNT]: tripCount = NODATA,
      [VESSEL_STAT__TOTAL_DIST]: distanceTraveled = NODATA,
      [VESSEL_STAT__TOTAL_FUEL]: fuelUsed = NODATA,
      [VESSEL_STAT__TOTAL_ONLINE]: timeOnline = NODATA
    } = asObj;

    let avgTripDist = NODATA;
    if (isValidPgnValue(distanceTraveled) && isValidPgnValue(tripCount) && tripCount > 0)
      avgTripDist = distanceTraveled / tripCount;

    return {
      TripCnt: tripCount,
      TotDist: distanceTraveled,
      AvgDist: avgTripDist,
      TotFuel: fuelUsed,
      AvgFuelEco: calculateFuelEconomy(fuelUsed, distanceTraveled),
      TOnl: timeOnline
    };
  }

  /**
   * Gets an array of warnings raised by the given data source.
   * 
   * @param {string} dataSrcName 
   * @returns {import('../../api/warnings_api').Warning[]}
   */
  getDeviceWarnings(dataSrcName) {
    const warnings = this.getWarnings();
    if (!warnings)
      return null;

    return warnings.filter(w => w.SrcName === dataSrcName);
  }

  /**
   * Gets the associated vessel's warnings.
   * 
   * @returns {import('../../api/warnings_api').Warning[]}
   */
  getWarnings() {
    const asObj = this._activeSessionObj;
    if (!asObj)
      return null;

    return asObj.Warnings;
  }
}