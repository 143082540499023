/*
  Exception class thrown by the ApiClient in case a response was received but its status code indicated an error.

  Public API exposed via props.
    -statusCode: number // Response status code
    (Other fields and methods inherited from Error)
*/
class HttpException extends Error {
  constructor(message, statusCode) {
    super(message);

    this.statusCode = statusCode;
  }
};

export default HttpException;