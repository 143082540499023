import { APPLICATION_THEME } from './consts';

export const canGetThemeFromUserAgent = !!window.matchMedia;

export const getThemeFromUserAgent = () => {
  if (canGetThemeFromUserAgent) {
    const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    return darkThemeMediaQuery.matches ? APPLICATION_THEME.LXNAV_DARK : APPLICATION_THEME.LXNAV_LIGHT;
  }

  return null;
};

/**
 * Retrieves the current URL of the application in the form excluding the scheme and authority.
 *
 * @returns string
 */
export const getCurrentUrl = () => {
  const {
    pathname,
    search,
    hash
  } = window.location;

  return `${pathname}${search}${hash}`;
};