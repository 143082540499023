/*
  Theme switch for application. The switch will automatically read the current theme from the user's display preferences, and change the theme via them.
  If the display preference's theme is changed the switch will update to reflect it as well. Based off of EuiSwitch implementation.

  Public API exposed via props:
  NONE
*/

import React from 'react';
import cx from 'classnames';

import { useTheme } from '../../../../hooks/use_theme';
import { APPLICATION_THEME } from '../../../../utils/consts';

/*
  Internal component used to render the actual switch.
  -isDark: boolean, // Is the current theme dark
  -onChange: (void) => void // Callback to invoke when the switch is toggled 
*/
const ThemeSwitch = ({
  isDark,
  onChange,
  isDisabled = false
}) => (
  <button
    aria-checked={!isDark}
    disabled={isDisabled}
    role='switch'
    className={cx('lxnavThemeSwitch__button', { 'lxnavThemeSwitch__button--checked': isDark })}
    onClick={onChange}
  >
    <span className='lxnavThemeSwitch__body'>
      <span className='lxnavThemeSwitch__thumb' />

      <span className='lxnavThemeSwitch__track'>
        <span className='lxnavThemeSwitch__label'>
          DARK
        </span>

        <span className='lxnavThemeSwitch__label lxnavThemeSwitch__label--checked'>
          LIGHT
        </span>
      </span>
    </span>
  </button>
);

/*
  This component renders the above ThemeSwitch, connected to the global state of the application.
  Toggling it will change the app's theme and vice versa.

  Puclic API exposed via props:
  - NONE
*/
const ConnectedThemeSwitch = ({ isDisabled = false }) => {
  const [theme, setTheme] = useTheme();
  const isCurrThemeDark = theme === APPLICATION_THEME.LXNAV_DARK;

  const switchToggled = () => {
    const newThemeName = isCurrThemeDark ? APPLICATION_THEME.LXNAV_LIGHT : APPLICATION_THEME.LXNAV_DARK;
    setTheme(newThemeName);
  };

  return (
    <ThemeSwitch
      onChange={switchToggled}
      isDark={isCurrThemeDark}
      isDisabled={isDisabled}
    />
  );
}

export default ConnectedThemeSwitch;